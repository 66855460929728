.portal-collapsible-panel {
  background: $theme-white !important;
  border-radius: 3px !important;
  border: 1px solid $border-color-base !important;
  margin: 0 0 $margin-lg !important;

  .ant-collapse-header {
    cursor: default !important;
    margin: $margin-lg !important;
    padding: 0 !important;
    line-height: 32px !important;
  }

  &-title {
    color: $theme-black;
    font-size: 16px;
    font-weight: $font-weight-bold;
  }

  &-collapse-expand-button {
    padding: 0 $margin-lg !important;
  }
}
