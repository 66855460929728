.even-notify {
  margin-top: 50px;
  margin-right: -10px;

  .ant-notification-notice-btn {
    text-align: left;
    float: none;
    margin-left: 48px;
    margin-top: 8px;
    display: block;
  }

  .ant-notification-notice-close {
    display: none;
  }
}

.notification-success {
  color: $aare-river;
}

.notification-info {
  color: $janitor;
}

.notification-warning {
  color: $theme-orange;
}

.notification-error {
  color: $theme-red;
}

.notification-show-more {
  margin-left: 48px;
}
