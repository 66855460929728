.notification-show-more {
  margin-left: 48px;
}

.ant-notification-notice-message {
  font-weight: $font-weight-bold;

  &.read {
    font-weight: $font-weight-normal;
  }
}

.ant-notification-notice-description {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 24px;
}

.ant-notification-notice-description {
  .notification-show-more,
  .ant-notification-notice-description {
    margin-left: 0 !important;
  }
}
