.choose-plan-radio-group {
  .ant-radio-group {
    display: flex;

    .ant-radio-button-wrapper {
      height: unset;
      padding: 15px;
      width: 162px;
      margin-right: 16px;
      border-radius: 4px !important;
      text-align: center;

      .purchase-plan-title {
        font-size: 18px;
        margin-bottom: 7px;
        font-weight: $font-weight-normal;
      }

      &.ant-radio-button-wrapper-checked {
        .purchase-plan-title {
          color: $theme-gray-11;
        }
      }

      .purchase-plan-price {
        color: $theme-blue;
        font-weight: $font-weight-bold;
        margin-bottom: 0;
      }
    }

    .ant-radio-button-wrapper:last-of-type {
      margin-right: 0;
    }
  }
}
