.integration-create {
  .integration-name-field {
    width: 100%;
    text-align: left;
  }

  .integration-name-field-text {
    float: none;
  }

  .integration-upload-field .portal-form-field-element {
    display: block;
  }
}
