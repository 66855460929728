.plan-summary-details {
  width: 417px;

  .detail-group {
    margin-top: 15px;
    margin-bottom: 15px;

    &:first-child {
      margin-top: 0;
    }

    > * {
      margin-bottom: 5px;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    h4 {
      font-weight: bold;
    }

    .ant-btn--soft-disabled {
      pointer-events: none;
    }
  }
}
