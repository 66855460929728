.demand-subaccount-details {
  .demand-api-badge {
    margin-bottom: 10px;
  }

  .column-badge {
    margin-right: 10px;
  }

  .table-wrapper {
    width: 100%;
    height: 111px;
  }
}
