.subscription-plans {
  width: 100%;
  max-width: 888px;
  margin: 30px auto 0;

  @media (max-width: 1180px) {
    padding-right: $padding-lg;
    padding-left: $padding-lg;
  }

  @media (min-width: 920px) {
    display: grid;
    justify-content: space-around;
    grid-column-gap: $padding-lg;
    grid-template-columns: repeat(3, 1fr);
  }

  &-footnote {
    width: 100%;
    max-width: 888px;
    margin: 32px auto;

    @media (max-width: 1180px) {
      padding-right: $padding-lg;
      padding-left: $padding-lg;
    }

    ol {
      padding-left: $padding-lg;
      border-left: 2px solid $theme-blue;
      margin-bottom: 0;
      color: $theme-gray-9;
      font-size: 14px;
      line-height: normal;
    }
  }
}

.subscription-text {
  width: 100%;
  max-width: 888px;
  margin: 30px auto 0;
  text-align: center;

  svg {
    color: $theme-blue;
  }
}
