.terms-of-service-modal {
  .ant-modal-footer {
    padding: 24px 200px 48px;
    border: none;
  }
}

.terms-of-service-text {
  font-size: 12px !important;
}

.download-msa-cca {
  border-top: 1px dotted $cerebral-grey;
  margin-top: 4px;
  padding-top: 4px;
  font-size: 11px;
}
