.contract-card-buttons {
  display: flex;

  button {
    margin-right: 10px;
  }
}

.contract-history-card {
  .ant-table-wrapper {
    overflow-x: auto;
  }
}

.contract-history-card
  > div.ant-card-body
  > div
  > div
  > div
  > .ant-table-pagination {
  padding-right: 10px;
}
