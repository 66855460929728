.welcome-tutorial {
  display: flex;
  min-height: 100%;
  max-width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: $theme-gray-11;

  &-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.background-starts {
      background-image: url("/static/stars.svg") !important;
      background-position: center;
      background-repeat: no-repeat;
    }

    .logo-wrapper {
      text-align: center;

      .logo {
        font-size: 100px;
      }
    }

    .carousel-section {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 90%;
      align-items: center;
      justify-content: center;

      &-button-wrapper {
        display: flex;
        width: 20%;
        color: $theme-white;

        &.button-wrapper-right {
          justify-content: flex-start;
        }

        &.button-wrapper-left {
          justify-content: flex-end;
        }

        .carousel-button {
          height: 60px;
          width: 60px;
          margin: 0 20px;

          .button-icon {
            font-size: 32px;
            color: $celtic-blue;
            stroke: $celtic-blue;

            &.arrow-right {
              margin-top: -2px;
              transform: rotate(180deg);
            }

            &.arrow-left {
              margin-top: 4px;
            }
          }
        }
      }

      &-carousel-wrapper {
        width: 60%;
      }
    }
  }
}
