.breakdown-table {
  .portal-plist-title {
    font-weight: 800;
  }

  .breakdown-table-footer {
    display: flex;
    justify-content: space-between;

    h1 {
      font-weight: bold;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
}
