@import "../variables";

@mixin input {
  width: 100%;
  min-height: 32px;
  padding: 0 16px;
  border: 1px solid $border-color-base;
  background: $theme-white;
  border-radius: 4px;
  box-shadow: none;
  color: $text-color;
  outline: 0;
  transition: 0.5s all ease;
}

@mixin input-active {
  border-color: $daphne;
  box-shadow: none;
}

@mixin input-focus {
  border-color: $adamantine-blue;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px rgb(34 139 230 / 20%);
  outline: 0;
}

@mixin reset-input-focus {
  border-color: unset;
  border-right-width: unset;
  box-shadow: unset;
  outline: unset;
}

@mixin input-hover {
  border-color: $adamantine-blue;
  border-right-width: 1px !important;
}

@mixin reset-input-hover {
  border-color: unset;
  border-right-width: unset;
}

@mixin input-error {
  border-color: $theme-red;
}

@mixin input-disabled {
  background: $white-smoke;
  box-shadow: unset;
  outline: 0;
}
