.lead-dedupe-card {
  .lead-deduplication-form {
    margin-bottom: 60px;
  }

  &-row {
    margin: 16px 0;
    width: 100%;
  }
}
