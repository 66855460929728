.copy-embed-code-modal {
  .ant-modal-title {
    color: $theme-black;
    font-weight: $font-weight-bold;
  }

  &-actions {
    margin-bottom: 8px;
    font-size: 12px;
  }

  &-highlighter {
    width: 100%;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
}
