// blueprint styles
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

// antd styles
@import "~antd/lib/modal/style/index";
@import "~antd/lib/input-number/style/index";
@import "~antd/lib/alert/style/index";
@import "~antd/lib/upload/style/index";
@import "~antd/lib/tree-select/style/index";
@import "~antd/lib/collapse/style/index";
@import "~antd/lib/avatar/style/index";
@import "~antd/lib/drawer/style/index";
@import "~antd/lib/input/style/index";
@import "~antd/lib/menu/style/index";

// ag-grid styles
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

// variables must be imported first
@import "variables";

// blueprint overrides
@import "blueprint-component-styling";
@import "ag-grid";

// helpers
@import "global-helpers";

/* stylelint-disable */
#__next {
  height: 100%;
}
/* stylelint-enable */

/**
* TODO: Perform Antd V4 migration before moving hardcoded .ant stylings out of _app and into
* wrapped components as classNames/nested HTML elements may be differ between these versions
*/
.portal-layout {
  .ant,
  &.ant {
    color: $theme-gray-9;
    font-weight: $font-weight-bold;

    &-tabs-tab {
      padding: 12px 16px;
      margin: 0 32px 0 0;
    }

    &-card {
      border: 1px solid $border-color-base;
      margin-bottom: 20px;
      border-radius: $border-radius-base;

      &-head-title {
        color: $theme-black;
        font-weight: $font-weight-bold;
      }
    }

    &-layout {
      min-height: 100%;
      background-color: $theme-gray-3 !important;
      -webkit-overflow-scrolling: touch;
    }

    &-pagination-total-text {
      font-weight: $font-weight-semibold;
    }

    &-drawer-title,
    &-table-column-title,
    &-table-thead th {
      /* stylelint-disable scss/at-extend-no-missing-placeholder */
      @extend .ant;
    }
  }

  .rounded-card-simple {
    padding: $padding-lg;
    margin-bottom: $margin-lg;
  }
}

.portal-content {
  display: flex;
  height: 100%; /* safari issue with flexbox auto not having parent with defined height */
  flex-direction: column;
  margin: $margin-lg;

  &-full {
    margin: 0;
  }
}

.portal-content-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  &-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.portal-content-footnote {
  padding: 0 20px 20px;
}

.message-container {
  margin-top: 250px;
}

.new-feature {
  span {
    font-weight: $font-weight-semibold;
  }
}

.theme-green {
  background-color: $theme-green !important;
  color: $theme-white !important;
}

body {
  font-family: $font-family !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;

  a {
    color: $theme-blue;
  }

  .ant-input-search-icon {
    display: inline-block !important;
    width: auto !important;
    height: auto !important;
  }
}
