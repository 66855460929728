.revenue-reports {
  &-client-tag {
    max-width: 100px;
  }

  &-subaccounts {
    width: 300px;

    &-dropdown {
      overflow: auto;
      max-width: 400px;
      max-height: 500px !important;
    }
  }
}
