.lead-hydration-card {
  .even-table-container {
    width: 100%;
  }

  .ant-input-number {
    width: 100%;
  }
}

.lead-hydration-hidden {
  .legacy-portal-form {
    display: none;
  }
}
