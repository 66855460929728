@import "../../../../../styles/mixins/table-as-card";

.billing-cycle-card {
  @include table-as-card;

  &-current {
    margin: 25px;

    .portal-netd-icon {
      display: inline-block;
      padding-top: 10px;
      margin-right: 25px;
    }

    &-cycle {
      font-size: 14px;
      margin-right: 25px;
    }

    &-effective {
      font-size: 12px;
      color: $theme-gray-8;
      font-style: italic;
    }
  }
}
