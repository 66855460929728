@import "../../../styles/mixins/ant-success-alert-override";

.self-service-home-scope {
  h1 {
    font-size: 30px;
    line-height: normal;
    margin: 0 0 15px;
  }

  @include ant-success-alert-override;

  .performance-summary-card {
    .ant-card-head {
      .ant-card-head-title {
        color: $ships-officer;

        strong {
          font-weight: $font-weight-bold;
        }
      }
    }

    .performance-summary-stat-bar:last-child {
      .stat-bar {
        margin-bottom: 0;
      }
    }
  }

  .getting-started-box {
    background: $theme-gray-4 padding-box;
    border: 1px solid $cold-morning;
    padding: $padding-lg;
    margin-bottom: $margin-lg;

    .ant-steps-item-title {
      display: flex;
    }

    &-title {
      font-size: 18px;
      font-weight: $font-weight-bold;
    }

    &-tip {
      font-size: 12px;
      margin-bottom: 32px;
    }

    .portal-step-button {
      min-width: 126px;
    }
  }

  .ant-table-empty {
    .ant-table-cell {
      border: 0 !important;
    }
  }

  .ant-card {
    border: 1px solid $cold-morning;
  }

  .banking-information {
    margin-top: 0 !important;

    &-content {
      padding-bottom: 24px;
    }

    .ant-card-body {
      padding-top: unset;
    }

    .ant-card-head {
      border-bottom: unset;
    }

    & > div {
      max-width: 100%;
      flex: 1;
    }
  }
}

.new-feature {
  margin-bottom: 6px;
}
