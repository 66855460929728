.portal-netd-icon {
  font-size: 18px;
  font-weight: $font-weight-semibold;
  margin-bottom: 10px;
  color: $theme-blue;

  svg {
    margin-right: 5px;
  }
}
