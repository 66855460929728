.integrations-data {
  &-extra {
    padding: 0 0 $margin-lg 0;

    h5 {
      font-size: 16px;
      font-weight: $font-weight-bold;
    }
  }

  &-table {
    padding: 0;

    .ant-table-content {
      overflow: visible !important;
    }

    .anticon {
      width: auto !important;
      height: auto !important;
    }

    .ant-table-wrapper.even-table {
      padding-bottom: 25px;
    }

    .ant-table-filter-column {
      padding: 16px 0 !important;
    }

    .portal-clipboard {
      white-space: nowrap;
    }

    .icon {
      margin-right: 16px;
      font-size: 17px;
      vertical-align: middle;
    }

    .even-table {
      &-action-bar {
        margin-bottom: 0;

        &-title {
          color: $theme-gray-9;
        }
      }
    }

    .ant-table {
      background: transparent;

      table {
        border-spacing: 0 1px !important;
        width: calc(
          100% - 4px
        ) !important; // needed to display box shadow properly

        margin-left: 2px;
        margin-right: 2px;

        th {
          color: $theme-gray-9 !important;
          font-size: 12px !important;
          font-weight: $font-weight-bold !important;
          white-space: nowrap;

          &.ant-table-column-has-sorters {
            &:nth-child(2) {
              padding-left: 16px !important;
            }
          }
        }

        thead {
          tr,
          th {
            background-color: transparent !important;
            padding-left: 0;

            div {
              padding-top: 0 !important;
            }

            :nth-child(2) {
              padding-left: 0 !important;

              > div {
                padding-left: 0 !important;
              }
            }
          }
        }

        /* stylelint-disable no-descending-specificity */
        tbody {
          td {
            td {
              padding: $margin-lg;

              &:last-of-type {
                padding-right: 0;
              }
            }

            &:nth-child(2) {
              padding-left: 16px !important;
            }
          }
          /* stylelint-enable no-descending-specificity */
          tr.ant-table-expanded-row {
            background-color: transparent !important;
            box-shadow: none;

            td {
              background: transparent !important;
              padding: 0 0 $padding-lg 0;

              .integration-expanded-row {
                padding: $margin-lg;
                background: $theme-gray-4;
                box-shadow: 0 1px 2px $black-75pcnt;
                border-radius: 0 0 3px 3px;
              }
            }
          }
        }
      }

      &-column-sorters {
        padding: 10px 8px;

        &:first-of-type {
          padding-left: 14px;
        }
      }

      &-row-expand-icon-cell {
        display: none !important;
      }
    }
  }
}
