.right-sidebar-item {
  margin: $margin-lg 0;
  line-height: normal;

  :first-of-type {
    margin-top: 0;
  }

  &-subtitle {
    margin-bottom: 10px;
    color: $theme-gray-9;
    font-size: 12px;
    font-weight: $font-weight-bold;
  }

  &-title {
    margin-bottom: 10px;
    color: $wild-dove;
    font-size: 14px;
    font-weight: $font-weight-bold;
    letter-spacing: 1.82px;
    text-transform: uppercase;
  }

  &-body {
    display: flex;
    width: 100%;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: 12px;

    &-content {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    a {
      color: $primary-color;
    }

    a:not(:last-child) {
      margin-bottom: 8px;
    }

    .anticon {
      margin-right: 4px;
    }
  }
}
