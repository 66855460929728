.bp4-checkbox {
  .bp4-control-indicator {
    background: $theme-white !important;
    box-shadow: inset 0 0 0 1px $checkbox-active-color,
      inset 0 -1px 0 $checkbox-active-color !important;
  }

  input:focus ~ .bp4-control-indicator {
    outline: none !important;
  }

  input:checked ~ .bp4-control-indicator {
    background-color: $checkbox-active-color !important;
  }

  input:hover:not(:checked) ~ .bp4-control-indicator {
    box-shadow: inset 0 0 0 1px $blue-jeans, inset 0 -1px 0 $blue-jeans !important;
  }
}
