@mixin tabs-navbar-center {
  [role="tab"] {
    width: auto;
  }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
}
