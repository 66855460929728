.order-total-summary {
  .order-total-title {
    font-weight: $font-weight-bold;
    color: $theme-gray-9;
    margin: 0 0 10px;
    text-align: left;
  }

  .action-bar {
    white-space: nowrap;
    text-align: right;

    button {
      height: unset;
    }

    .purchase-btn {
      padding: 14px 18px 14px 14px;

      .ant-btn-loading-icon {
        .anticon {
          padding-right: 4px;
          padding-left: 4px;
        }
      }
    }

    .cancel-btn {
      padding: 14px 32px;
      margin-right: 16px;
      border: none;
      color: $theme-blue;
    }
  }

  .row {
    .row-label {
      color: $theme-gray-5;
    }

    .row-value {
      font-weight: $font-weight-semibold;
      color: $theme-gray-11;
    }
  }

  .implementation-fee-row {
    &-note {
      color: $theme-gray-9;
      font-weight: $font-weight-semibold;
      font-size: 12px;
    }

    .row-waived {
      text-decoration: line-through;
    }
  }

  .discount-row {
    .row-value {
      color: $theme-green-6;
    }
  }

  .price {
    font-size: 32px;
    color: $theme-blue;
    margin-bottom: 8px;
    margin-top: 16px;

    .price-note {
      font-size: 16px;
    }
  }

  .note-description-wrapper {
    max-width: 280px;
    display: block;
    margin-bottom: 10px;

    .note-description {
      font-style: italic;
      font-size: 10px;
    }
  }
}
