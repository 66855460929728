.layout-with-sidebar {
  $sidebar-width: 300px;

  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  .main-content {
    width: 100%;
    max-width: calc(100% - #{$sidebar-width});
  }

  .sidebar-content {
    width: auto;
    min-width: $sidebar-width;
    padding-left: $margin-lg * 2;
    white-space: nowrap;
  }
}

@media (max-width: 1080px) {
  .layout-with-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .main-content {
      width: 100%;
      max-width: 100%;
      margin-bottom: $margin-lg;
    }

    .sidebar-content {
      width: 100%;
      margin-left: 0;
    }
  }

  .portal-details-back {
    margin-top: 40px;
  }
}
