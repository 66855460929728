/* stylelint-disable no-descending-specificity */
.self-service-integration-details {
  .self-service-integration-header {
    background: $theme-white;
    border-top: 1px solid $border-color-base;
    padding: $padding-lg;
    padding-bottom: 0;

    .portal-details-back {
      font-size: 12px;
      padding-left: 0;

      .anticon {
        font-size: 10px;

        ~ span {
          margin-left: 6px;
        }
      }
    }

    .actions {
      margin-bottom: 8px;
    }

    .meta {
      display: flex;
      align-items: center;

      .details-icon {
        fill: $theme-blue;
        width: 18px;
      }

      &-status {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;

        &-divider {
          margin-right: 16px;
        }

        &-label {
          font-size: 12px;
          font-weight: $font-weight-bold;
          display: flex;
          align-items: center;
          margin-right: 8px;
          line-height: 24px;
        }

        .ant-badge-status-text {
          margin-left: 0;
        }

        :last-child {
          margin-right: 0;
        }
      }

      .ant-tag {
        color: $theme-gray-9;
        border: 1px solid $snowbank;
        padding: 4px 8px;
        padding-bottom: 3px;
        margin: 0 16px;
      }
    }

    h1 {
      font-size: 22px;
      font-weight: $font-weight-bold;
      margin: 0;
      padding-top: 16px;
      padding-bottom: 12px;
    }
  }

  .tracking-tab-title {
    position: relative;
    color: $theme-blue;

    svg {
      top: 0;
      position: absolute;
      width: 8px;
      height: auto;
    }
  }

  .ant-tabs {
    &-content-holder {
      padding: 0 $padding-lg;
      margin-bottom: $padding-lg;
    }

    &-tab {
      padding-left: 0;
      padding-right: 0;
    }

    &-tab-btn {
      font-size: 14px;
      letter-spacing: 0.56px;

      &[aria-selected="false"] span {
        color: $theme-gray-6;
      }
    }

    &-nav {
      padding: 0 $padding-lg;
      margin-bottom: 0;
      background: $theme-white;
      border-bottom: 1px solid $cold-morning;

      &-wrap {
        background-color: $theme-white;
      }
    }

    .layout-with-sidebar {
      margin-top: $padding-lg;
    }
  }
}
