@import "~ag-grid-community/src/styles/ag-grid";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "./variables";

$table-border: $lilac-mist;
$table-hover: $cotton-ball;
$table-selected: $snowflake;
$table-row: $theme-white;
$table-row-odd: $doctor;
$table-cell-focus-border: $daphne;

// Reference https://www.ag-grid.com/react-data-grid/themes-customising/ first.
.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      header-column-resize-handle: false,
      header-column-separator: true,
      header-column-separator-color: $table-border,
      background-color: $table-row,
      foreground-color: $ships-officer,
      header-background-color: $theme-white,
      odd-row-background-color: $table-row-odd,
      border-color: $table-border,
      row-height: ag-derived(grid-size, $times: 9, $plus: 2),
      header-height: ag-derived(grid-size, $times: 8, $plus: 4),
      cell-horizontal-padding: ag-derived(grid-size, $times: 4),
      row-hover-color: $table-hover,
      font-size: 12px,
      font-family: $font-family,
      control-panel-background-color: $theme-white,
      selected-row-background-color: $table-selected,
      cell-horizontal-border: solid $table-border,
      row-border-color: null,
      range-selection-border-color: transparent,
    )
  );

  // Table border-radius is not supported as a theme customization option.
  .ag-root-wrapper {
    border-radius: 4px;
  }

  // Adding a bottom border to the last row.
  .ag-row-last {
    border-bottom: 1px solid $table-border;
  }

  // Out of the box support for removing the last row bottom border.
  .ag-disable-last-row-border {
    .ag-row-last {
      border-bottom: unset;
    }
  }

  // Making the Actions column full-width actionable.
  .ag-cell-action {
    padding: 0 !important;

    & > div > span {
      text-align: center;
    }

    & > div,
    & > div > span,
    & > div > span > span {
      width: 100%;
    }
  }

  // Fixing double border on the last header cell.
  .ag-header-row {
    .ag-header-cell:last-child::after {
      background-color: transparent;
    }
  }

  // Fixing double border on the last data row cell.
  .ag-row {
    .ag-cell:last-child {
      border-right-color: transparent;
    }
  }

  // Customizing A tag color and pseudo states.
  .ag-cell a {
    &:visited {
      color: $link-color-on-visited;
    }
  }

  // Customizing focus border color
  .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border: 1px solid $table-cell-focus-border;
  }

  // Customizing focus border color
  .ag-overlay-no-rows-center {
    padding-top: 65px;
  }
}

.ag-tabs-header.ag-menu-header {
  display: none;
}

// Resolves an issue with AGGrid auto-height being set too large by default
// which leads to extra, unnecessary space.
// https://github.com/ag-grid/ag-grid/issues/4095
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine .ag-layout-print .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-print .ag-center-cols-container {
  min-height: 0;
}
