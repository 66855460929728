.payout-settings-tab {
  .ssn-notice {
    max-width: 600px;
  }

  .notice-card {
    background: $white-smoke;
    border: 1px solid $lilac-mist;
    border-radius: 4px;
    padding: 8px 16px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .portal-form-field-list {
    max-width: 330px;
  }

  .portal-form-actions {
    margin-top: 24px;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
  }
}
