.tracking-tab-form {
  padding: 24px;

  &-disabled {
    pointer-events: none;
    opacity: 0.51;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid $border-color-base;
    padding-bottom: 8px;

    .header-title {
      color: $theme-black;
      font-weight: 700;
      font-size: 14px;
    }

    .disabled-btn {
      pointer-events: none;
      opacity: 0.51;
    }
  }

  &-explanation-note {
    color: $theme-gray-8;
    font-size: 12px;
    padding-top: 8px;
  }

  &-explanation-list-wrapper {
    padding: 14px 0;
    list-style-position: inside;

    .explanation-list-item {
      font-size: 14px;
      color: $theme-black;

      .explanation {
        margin: 0;
        display: inline;
      }

      .explanation-example {
        color: $theme-blue;

        .attribute {
          color: $theme-gray-8;
        }
      }

      .explanation-example::before {
        color: $theme-gray-8;
        content: "a. ";
      }
    }
  }

  &-documentation-wrapper {
    .explanation-documentation {
      color: $theme-gray-8;
    }
  }
}
