.date-list {
  display: flex;

  &-item {
    flex-grow: 1;
    display: grid;

    &-label {
      border-bottom: 1px $theme-gray-8 dashed;
      width: fit-content;
      color: $theme-gray-8;
      font-size: small;
    }

    &-value {
      font-size: large;
      font-weight: 600;
      margin-top: 5px;
    }
  }
}
