.bp4-button.bp4-intent-success:disabled,
.bp4-button.bp4-intent-success.bp4-disabled {
  opacity: 0.3;
}

.bp4-button:not(.blueprint-nav .bp4-button) {
  box-sizing: border-box;
  padding: 8px 24px;
  border-radius: 4px;
  box-shadow: unset !important;
  font-family: "Open Sans", sans-serif;
  line-height: 16px;
  outline: none !important;
  transition: all 0.2s;

  --webkit-box-shadow: unset !important;

  > * {
    flex-shrink: unset !important;
  }

  // Success button - Green Button in Figma
  &.bp4-intent-success:not(.bp4-minimal):not(.bp4-small) {
    border: 1px solid $success-btn-default-color !important;
    background: $success-btn-default-color !important;
    color: $theme-white !important;

    &:hover {
      border: 1px solid $success-btn-hover-color !important;
      background: $success-btn-hover-color !important;
      color: $theme-white !important;
    }

    &:active {
      border: 1px solid $success-btn-active-color !important;
      background: $success-btn-active-color !important;
      color: $theme-white !important;
    }
  }

  // Primary button
  &.bp4-intent-primary:not(.bp4-minimal) {
    border: 1px solid $primary-btn-default-color !important;
    background-color: $primary-btn-default-color !important;
    box-shadow: none;
    color: $theme-white !important;

    &:hover {
      border: 1px solid $primary-btn-hover-color !important;
      background: $primary-btn-hover-color !important;
      color: $theme-white !important;
    }

    &:active {
      border: 1px solid $primary-btn-active-color !important;
      background: $primary-btn-active-color !important;
      color: $theme-white !important;
    }

    &:disabled {
      background: $primary-btn-disabled-color !important;
      border: 1px solid $primary-btn-disabled-color !important;
      color: $theme-white !important;
    }
  }

  // Primary outlined button - Secondary in Figma
  &.bp4-intent-primary.bp4-outlined:not(.bp4-minimal) {
    border: 1px solid $primary-btn-outlined-default-color !important;
    background: $theme-white !important;
    color: $primary-btn-outlined-text-color !important;

    &.bp4-active {
      border: 1px solid $primary-btn-hover-color !important;
      color: $primary-btn-hover-color !important;
    }

    &.tertiary {
      border: 1px solid $primary-btn-default-color !important;
      background: $theme-white !important;
      color: $primary-btn-default-color !important;
    }

    &:hover {
      border: 1px solid $primary-btn-hover-color !important;
      background: $theme-white !important;
      color: $primary-btn-hover-color !important;
    }

    &:active {
      border: 1px solid $primary-btn-active-color;
      background: $theme-white !important;
      color: $primary-btn-active-color !important;
    }
  }

  &.bp4-minimal {
    color: $theme-blue !important;
    line-height: normal !important;

    &:hover {
      color: $link-color-on-hover !important;
    }

    &:focus {
      color: $link-color-on-hover !important;
      outline: 0;
    }

    &:hover,
    &:focus {
      background-color: color.adjust($link-color, $alpha: -0.85) !important;
    }
  }
}
