.text-right {
  text-align: right;
}

.float-right {
  float: right;
}

.hidden {
  display: none !important;
}

.padding-none {
  padding: 0 !important;
}

.pr-10 {
  padding-right: 10px;
}

.margin-none {
  margin: 0 !important;
}

.width-100 {
  width: 100%;
}

.error-text {
  margin-bottom: 5px;
  color: $theme-red;
  font-size: 12px;
  font-weight: $font-weight-bold;
}

.text-normal {
  font-weight: normal;
}

.text-semibold {
  font-weight: $font-weight-semibold;
}

.text-green {
  color: $theme-green;

  &-6 {
    color: $theme-green-6;
  }
}

.vertical-form-container {
  width: 75%;
  margin: 0 auto;
  padding: 30px;
}

.vertical-form-submit {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 0;
}
