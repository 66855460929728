.card-table {
  $std-border: 1px solid $cold-morning;

  overflow: auto;

  table {
    border-spacing: 0 1px !important;
  }

  .ant-table-thead {
    .ant-table-cell {
      background: $theme-white;
      color: $theme-gray-8;
      text-align: left;
      font-size: 12px;
      font-weight: $font-weight-normal;
      border-bottom: unset;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .ant-table-row {
    box-shadow: 0 1px 2px $black-75pcnt;
    border-radius: 3px;

    & > td {
      border-top: $std-border;
      border-bottom: $std-border;
    }

    & > td:first-child {
      border-radius: 3px 0 0 3px;
      border-left: $std-border;
    }

    & > td:last-child {
      border-radius: 0 3px 3px 0;
      border-right: $std-border;
      display: flex;
      justify-content: flex-end;
    }
  }
}
