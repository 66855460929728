.activate-deactivate-list {
  max-height: 250px;
  overflow: auto;
}

.modal-title-bold {
  .ant-modal-title {
    font-weight: $font-weight-bold;
  }
}
