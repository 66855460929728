.portal-plist {
  // .portal-plist-title may still be needed in global scope
  &-title {
    margin-bottom: 10px;
    color: $theme-gray-7;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
