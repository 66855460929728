@import "../../styles/variables";

.bp4-tabs {
  font-family: "Open Sans", sans-serif;

  .bp4-tab-list {
    padding: 0 $padding-lg;
    margin: 0 0 $padding-lg;
    background-color: $theme-white;
    border-radius: 0 0 $border-radius-base $border-radius-base;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow-x: auto;
  }

  .bp4-tab {
    height: 40px;
    font-weight: bold;
    font-weight: 300;
  }

  // for indicator animated
  .bp4-tab-indicator {
    height: 1px !important;
    color: $daphne !important;
  }

  // for indicator not animated
  .bp4-tab[aria-selected="true"] {
    box-shadow: inset 0 -1px 0 $daphne;
    color: $daphne !important;
  }
}
