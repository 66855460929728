.expanded-filter {
  padding: 10px 20px;
}

.rules-table {
  table {
    tr,
    th {
      background: transparent !important;
    }

    th,
    td {
      border: 0 !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }

  .ant-table {
    background: transparent !important;
    margin: 0 !important;
  }

  .ant-table-expand-icon-col {
    width: 16px;
  }

  .ant-table-row-expand-icon-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ant-table-expanded-row {
    > td {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.rules-table.depth-1 {
  margin-left: -16px;

  th,
  td {
    &:nth-of-type(2) {
      padding-left: 0 !important;
    }
  }

  .ant-table-row-expand-icon {
    margin-left: -8px !important;
  }
}
