.publish-integration-description {
  margin-top: 18px;
  line-height: 14px;
  width: 80%;

  ul {
    margin: 0;
    padding: 0;
    list-style: inside;

    li {
      padding: 3px 0;

      &:first-of-type {
        margin-top: 11px;
      }

      &:last-of-type {
        margin-bottom: 11px;
      }
    }
  }
}
