@import "../../../styles/mixins/table-as-card";

.partner-key-row {
  &-badge {
    margin-left: 20px;
  }
}

.partner-key-row:not(:last-child) {
  margin-bottom: 20px;
}

.supply-partner-tokens {
  @include table-as-card;

  &-alert {
    margin: 20px;
  }
}

.portal-home-heading {
  margin-bottom: 20px;
}

.home-contact-list {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .portal-details-back {
    margin-top: 40px;
  }
}
