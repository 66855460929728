@import "../../../../styles/mixins/table-as-card";

.filters-table {
  @include table-as-card;

  border: none;

  &-alert {
    margin: $padding-lg !important;
  }

  &-row-actions {
    span {
      cursor: pointer;
      color: $theme-blue;

      &:not(:last-of-type) {
        margin: 0 10px 0 0;
      }

      .confirmable-action {
        display: inline;
      }
    }
  }
}

.ant-tabs-tab {
  min-width: 80px;
  justify-content: center;
}

.expanded-filter {
  &-title {
    font-weight: bold;
    margin: 0 0 8px;
  }

  &-content {
    &:not(:last-of-type) {
      margin: 0 0 23px;
    }
  }
}
