.ant-steps-item-custom .ant-steps-item-icon {
  height: 32px !important;
  border: 1px solid $theme-gray-5 !important;

  .ant-steps-icon {
    display: none;
    font-size: 16px !important;
  }
}

.ant-steps-item-custom.ant-steps-item-finish .ant-steps-item-icon {
  border-color: $theme-blue !important;

  .ant-steps-icon {
    display: initial;
  }
}

.ant-steps-item-custom.ant-steps-item-wait .ant-steps-item-icon {
  border: 1px dashed $theme-gray-5 !important;
}

.portal-step {
  &-title {
    width: 100%;

    &-active {
      font-weight: $font-weight-bold;
    }
  }

  &-button,
  &-custom-action-content {
    position: absolute !important;
    right: 0;
    top: 50%;
  }

  &-button {
    &:disabled {
      opacity: 0.5 !important;
      background-color: $theme-blue !important;
      color: $theme-white !important;
    }

    &-gray:disabled {
      background-color: $theme-gray-5 !important;
      border-color: $theme-gray-5 !important;
      cursor: not-allowed !important;
      opacity: 0.5 !important;
    }
  }

  &-custom-action-content {
    color: $theme-gray-9;
    font-size: 14px;
  }

  .ant-steps-item {
    &:not(.ant-steps-item-active) {
      .ant-steps-item-icon {
        background-color: transparent !important;
      }
    }

    &-title,
    &-description {
      padding-right: 125px; // distance from button
    }

    &-title {
      font-size: 16px;
      width: 100%;
    }

    &-description {
      font-size: 12px !important;
    }

    &-container {
      padding-bottom: 6px;
    }

    &-tail {
      &::after {
        background-color: $silverback !important;
      }
    }

    &-active {
      .ant-steps-item-tail::after {
        background-color: $theme-blue !important;
      }
    }
  }
}

.ant-steps-item-custom.ant-steps-item-hide-tail .ant-steps-item-tail {
  display: none !important;
}

.ant-steps-item-custom.ant-steps-item-wait.ant-steps-item-active
  .ant-steps-item-icon {
  border: 1px solid $theme-gray-5 !important;
}
