.bp4-dialog {
  min-width: 530px !important;
  max-width: 90%;
  background: $theme-white !important;
  box-shadow: 0 2px 6px rgb(43 58 72 / 16%) !important;
  border-radius: 4px !important;

  .bp4-dialog-body {
    h4 {
      color: $ships-officer;
      margin-top: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $ships-officer;
    }
  }
}

.bp4-multistep-dialog {
  .bp4-multistep-dialog-left-panel {
    display: none !important;
  }

  .bp4-multistep-dialog-right-panel {
    background-color: $theme-white !important;
    border-radius: 4px;
  }

  .bp4-multistep-dialog-footer {
    flex-direction: row-reverse;
    justify-content: end;
    border-top: 1px solid $lilac-mist !important;
  }

  .bp4-dialog-footer-actions {
    .bp4-button:not(.final-button) {
      margin-right: 10px;

      // hide back button
      &:first-of-type:not(:last-of-type) {
        display: none !important;
      }

      // style continue button
      &:last-of-type {
        border: 1px solid $lilac-mist !important;
        border-color: initial;
        background-color: $theme-white !important;
        background-image: initial;
        box-shadow: initial;
        box-shadow: none;
        color: $ships-officer !important;

        &:hover:not([disabled]) {
          border: 1px solid $primary-btn-hover-color !important;
          color: $primary-btn-hover-color !important;
        }

        &:active:not([disabled]) {
          border: 1px solid $primary-btn-active-color !important;
        }

        &[disabled] {
          border: 1px solid $lilac-mist;
          color: $cistern !important;
        }
      }
    }
  }

  &.danger-step {
    .bp4-dialog-footer-actions {
      .bp4-button {
        border: 1px solid $lilac-mist !important;

        &:last-of-type {
          color: $theme-red !important;

          &:focus:not([disabled]) {
            outline: none !important;
          }

          &:hover:not([disabled]) {
            border: 1px solid $theme-red !important;
            color: $theme-red !important;
          }

          &:active:not([disabled]) {
            border: 1px solid $theme-red !important;
            color: $theme-red !important;
          }

          &[disabled] {
            color: $theme-red !important;
          }
        }
      }
    }
  }
}
