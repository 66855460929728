@import "@portal/ui-lib/src/lib/_widgets/SidebarIcon/SidebarIcon";

.ant-menu-submenu-popup {
  .nav-menu-item-text {
    color: inherit;

    :hover {
      color: $theme-white;
    }
  }
}

.portal-layout {
  .nav-menu-item {
    text-align: left;
    letter-spacing: 0;

    &-text {
      color: inherit;
    }
  }

  .portal-sider {
    overflow-y: auto;
    position: sticky;
    top: 0;
    height: 100%;

    &-hidden {
      display: none;
    }

    &-menu-collapsed {
      .ant-menu-submenu-title {
        text-align: center;
      }

      .nav-menu-item {
        text-align: center;
      }
    }

    &-menu {
      background-color: $theme-gray-11;
      min-height: 100%;
      width: 100%;

      .ant-menu-item-locked {
        span {
          color: rgb(255 255 255 / 35%) !important;
        }

        cursor: pointer !important;
        color: rgb(255 255 255 / 35%) !important;
        opacity: 0.8;
      }

      .portal-logo {
        display: flex;
        align-items: center;
        margin: 0;
        color: $theme-white;
        height: 65px;
        position: sticky;
        top: 0;
        background-color: $theme-gray-11;
        z-index: 5;
        padding-left: 12px !important;

        &-collapsed {
          pointer-events: none;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 65px;
          padding: 10px;

          svg {
            height: 100%;
            transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }

        svg {
          height: 100%;
          transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .portal-logo-text {
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: bolder;

          &-beta-label {
            color: $theme-blue;
            margin-left: 3px;
            vertical-align: 9px;
            font-size: 9px;
          }
        }
      }

      .nav-menu-item-group {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0.5px;

        &-title {
          pointer-events: none;
        }

        > div {
          color: $theme-gray-7;
          font-weight: $font-weight-semibold;
        }

        > div:first-child {
          color: $theme-white;
          font-size: 12px;
          font-weight: $font-weight-semibold;
          letter-spacing: 2px;
          margin-top: 32px;
        }
      }

      .nav-menu-item-group.self-service-nav {
        > div:first-child {
          color: $theme-gray-7;
        }
      }

      .nav-menu-item {
        text-align: left;
        letter-spacing: 0;

        &-text {
          color: inherit;
        }

        &-locked-icon {
          margin: 0;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translate(50%, -50%);
        }
      }

      .nav-menu-submenu {
        text-align: left;

        ul {
          background-color: $theme-gray-11;

          li {
            background-color: $theme-gray-11;
          }
        }

        .nav-menu-item {
          letter-spacing: 0;

          &-text {
            color: inherit;
          }
        }
      }
    }
  }
}
