.sub-account-tab {
  .subaccount-row {
    margin-bottom: 20px;

    .ant-tag,
    .ant-badge {
      margin-left: 15px;
    }
  }
}
