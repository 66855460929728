.countdown {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 60%;
  padding-bottom: 100px;

  &-colon {
    font-size: 46px;
    color: $theme-white;
  }

  &-item {
    text-align: center;
    font-weight: $font-weight-bold;
    color: $theme-white;

    &-time {
      background-color: $component-background;
      border: 1px solid $peace;
      border-radius: 16px;
      color: $theme-blue;
      font-size: 46px;
      width: 80px;
      height: 80px;
      padding: 5px 0;
    }

    p {
      padding: 8px;
    }
  }
}
