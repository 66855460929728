.even-table {
  overflow: hidden;
  height: 100%;

  th {
    .ant-table-filter-trigger {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-table-filter-trigger-container {
      position: relative;
    }

    .ant-table-filter-column-title {
      padding-right: 0 !important;
      flex-grow: 0;
    }

    .ant-table-column-sorters-with-tooltip {
      width: unset !important;
    }
  }

  tr,
  th {
    background-color: $theme-white !important;
  }

  th,
  td {
    &.pricing-plan-revenue-clauses {
      width: 300px;

      .ant-tag {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }

  .ant-table {
    height: calc(100% - 48px);

    &-thead > tr > th,
    &-tbody > tr > td {
      padding: 16px 8px;
    }

    &-filter-column {
      padding: 16px 8px !important;
    }

    &-column-sorters {
      padding: 0 !important;
    }

    .user-avatar {
      display: flex;
      align-items: center;
    }

    &-container {
      height: inherit;
      min-height: 100%;
    }

    &-content {
      height: inherit;
      min-height: 100%;
      overflow: auto;
    }
  }

  .ant-spin {
    &-nested-loading {
      height: 100%;
    }

    &-container {
      height: 100%;
    }
  }

  &-action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &-buttons {
      > * {
        color: $theme-gray-9;
        display: inline-block;
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-title {
      color: $theme-black;
      font-size: 16px;
      font-weight: $font-weight-bold;
    }
  }

  &-container {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-subtitle {
    font-size: 12px;
  }
}

.portal-table-title {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .even-table {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    &-action-bar {
      margin-top: 30px;
      flex-direction: column;
      align-items: flex-start;

      &-title {
        margin-bottom: 10px;
      }

      &-buttons {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        button {
          margin-bottom: 10px;
        }
      }
    }
  }
}
