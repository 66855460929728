.status-dot {
  margin-right: 10px;
  white-space: nowrap;

  &-distanced {
    margin-left: 10px;
  }

  &.border-active .ant-badge-status-dot {
    border: 1px solid $border-color-base;
  }

  &-smaller {
    font-size: 12px;
  }
}
