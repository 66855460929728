.partner-page-wizard {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: -$margin-lg;
  display: flex;

  &-preview {
    flex-grow: 10;
    height: 100%;
  }

  &-customize {
    min-width: 455px;
    width: 455px;
    background-color: $theme-white;

    .partner-page-form {
      border-left: 1px solid $cold-morning;
      border-bottom: 1px solid $cold-morning;
      height: 100%;

      .ant-tabs.ant-tabs-top {
        height: 93%;

        .ant-tabs-nav {
          margin: 0;
          box-shadow: 0 0 8px 0 rgb(0 0 0 / 25%);
          padding-bottom: 12px;
          z-index: 100;
        }

        .ant-tabs-content-holder {
          .ant-tabs-content.ant-tabs-content-top {
            height: 100%;

            .form-fields-holder {
              background-color: $theme-white;
              position: relative;
              height: 100%;

              .form-fields {
                padding: 0 0 0 16px;
                height: 100%;

                .portal-form-field-list {
                  overflow: hidden;
                  overflow-y: scroll;
                  height: 96%;
                  padding-right: 8px;
                  padding-bottom: 16px;
                }

                .portal-form-actions {
                  position: absolute;
                  background-color: $theme-white;
                  box-shadow: 0 -1px 5px 0 rgb(0 0 0 / 25%);
                  width: 455px;
                  z-index: 100;
                  padding: 12px 30px;
                  margin: 0;
                  bottom: 0;
                  right: 0;
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
