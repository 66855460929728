@import "../mixins/form";

.bp4-portal {
  z-index: 9999;
}

.bp4-multi-select-popover {
  .bp4-menu {
    padding: 0;
  }
}

.bp4-popover-wrapper .bp4-popover-target {
  display: block;

  .bp4-multi-select {
    @include input;

    &:hover {
      @include input-hover;
    }

    &.bp4-active {
      @include input-focus;
    }
  }
}

.bp4-popover {
  .bp4-popover-arrow {
    display: none;
  }

  .bp4-popover-content {
    padding: 0 !important;
  }

  .bp4-input-group {
    padding: 10px 16px;

    > .bp4-input-action {
      top: 11px;
      right: 17px;
    }

    > .bp4-input {
      height: 40px;
      background-color: $white-smoke;
      box-shadow: none;

      &::placeholder {
        color: $cistern;
      }
    }

    > .bp4-icon:first-child {
      top: 14px;
      left: 16px;
      color: $cistern;
    }
  }
}
