@import "~@blueprintjs/core/lib/scss/variables";

.integrations-card {
  .ant-table-filter-trigger-container {
    display: none;
  }

  &-create-integration {
    margin: 0 15px;
  }

  &-icon-column {
    width: 30px;

    @at-root {
      td#{&} {
        border-left: 1px solid $cold-morning;
        border-radius: 3px 0 0 3px;
      }
    }

    & > span {
      display: flex;
    }
  }

  &-table {
    .integrations-card-icon-column {
      &-icon {
        color: $blue2;
      }
    }

    th {
      padding-bottom: 4px !important;
      white-space: nowrap;
    }

    .ant-table-row-expand-icon-cell {
      display: none !important;
    }

    .ant-table-expanded-row {
      td {
        overflow: visible !important;
        padding: 0 !important;
        border-bottom: none;
        background-color: transparent;
        white-space: normal !important;

        &:empty {
          padding: 10px !important;
          background: inherit;
        }

        .integration-expanded-row {
          padding: 20px;
          background: $cascading-white;
          border-radius: 0 0 3px 3px;
          box-shadow: 0 1px 2px $black-75pcnt;
        }
      }
    }
  }

  &-web-page-icon {
    width: 17px;
    height: 14px;
  }

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .status-dot {
    font-size: 12px;
  }
}
