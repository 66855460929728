.demand-subaccount-details {
  .demand-api-badge {
    margin-bottom: 10px;
  }

  .column-badge {
    margin-right: 10px;
  }
}

.field-subtext {
  color: $spanish-grey;
}
