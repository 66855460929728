.ssa-settings {
  .expected-earnings-share-card {
    margin-bottom: 20px;
  }

  .expected-earnings-share-card > div {
    border-radius: 6px;
  }

  .expected-earnings-shares-table {
    width: 100%;
  }
}
