.adjustments-table {
  margin-top: 40px;

  .portal-plist-title {
    font-weight: 800;
  }

  .adjustments-table-footer {
    display: flex;
    justify-content: space-between;

    h1 {
      font-weight: bold;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  .adjustments-date {
    font-size: 10px;
    color: $theme-gray-6;
  }

  .adjustments-amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
