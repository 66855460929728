.bp4-non-ideal-state {
  .bp4-heading {
    margin-bottom: 8px;
    line-height: 32px;

    strong {
      font-weight: 700;
    }
  }

  .bp4-non-ideal-state-visual {
    display: inherit;
    font-size: 80px;
    margin: 36px;
    margin-bottom: 28px;
  }

  & > span {
    margin-bottom: 24px;
    line-height: 22px;
  }

  & > * {
    max-width: 500px;
    color: $ships-officer;
  }
}
