.application-token-create {
  &-checkbox-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;

    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
}
