.portal-detail-card {
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .legacy-portal-form,
  .portal-form {
    width: 80%;
    margin-bottom: 15px;

    &-actions {
      float: right;
    }
  }
}
