.pricing-plan-label {
  font-size: 12px;
  font-weight: $font-weight-bold;
  margin: 10px 0;
  overflow: hidden;
}

.pricing-plan-list-item:not(:last-of-type) {
  margin-bottom: 10px;
}

.portal-pricing-plan {
  margin-top: 10px;

  &-grey {
    background-color: $gainsboro;
  }

  &-title {
    color: $theme-gray-8;
    font-size: 14px;
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }

  &-element {
    font-size: 12px;
    font-weight: $font-weight-bold;
    margin-bottom: 5px;
    margin-top: 10px;
    overflow: hidden;
  }

  &-cost-per-value-table {
    margin: 20px 40px;
  }

  .ant-tag {
    white-space: normal !important;
  }
}
