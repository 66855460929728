.portal-drawer-form {
  .ant-drawer-wrapper-body {
    -webkit-overflow-scrolling: touch;
  }

  &-container {
    display: flex;
    justify-content: space-between;

    .legacy-portal-form {
      flex-grow: 1;
      max-width: 100%;
      margin-bottom: 50px;

      &-actions {
        border-top: 1px solid $snowbank;
        background: $theme-white;
        position: fixed;
        bottom: 0;
        right: 0;
        padding: 10px 16px;
        width: 400px;
        z-index: 5;

        button {
          float: right;
          margin-left: 10px;
        }
      }
    }

    .portal-form {
      $action-bar-height: 55px;

      margin-bottom: $action-bar-height - $padding-lg;
      width: 100%;

      &-actions {
        border-top: 1px solid $snowbank;
        background: $theme-white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 $padding-lg;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: $action-bar-height;

        button {
          margin-left: 10px;
        }
      }
    }

    .portal-form-field {
      max-width: 100%;
    }

    .portal-drawer-form-preview {
      flex-grow: 5;
    }

    .portal-drawer-form-preview:empty {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .portal-drawer-form {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}
