.hippo-embed-frame-wrapper {
  position: relative;

  .loading-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .hippo-embed-frame {
    &.loading {
      display: none;
    }
  }
}
