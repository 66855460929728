/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.Dots-module_dots__jSs7x {
  text-align: center;
}
.Dots-module_dots-dot__Js7dj {
  padding: 0 5px;
  color: #e4e5e7;
}
.Dots-module_dots-dot-active__2srn7 {
  color: #228be6;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.rounded-card-container {
  padding: 24px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 0;
  margin-bottom: 30px;
}
.rounded-card-container .title {
  font-size: 16px;
  font-weight: bold;
}
.rounded-card-container .rounded-card-header {
  display: flex;
  min-height: 65px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
  border-bottom: 1px solid #d9d9d9;
}
.rounded-card-container .rounded-card-content {
  padding: 20px;
}
.rounded-card-container .rounded-card-footer {
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: -20px;
  display: flex;
}
.rounded-card-container .header-content {
  display: flex;
  align-items: center;
}
.rounded-card-container .header-content > *:not(:last-child) {
  margin-right: 20px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.field-tooltip svg {
  color: #abb0b6;
}
.truncate .truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.truncate span {
  display: block;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.field {
  margin: 0 0 20px;
}
.field .bp4-popover2-content {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.field .bp4-popover2-arrow-fill {
  fill: rgba(0, 0, 0, 0.75) !important;
}

.field-label {
  display: block;
  margin: 0 0 5px;
  color: #000;
  font-weight: bold;
}

.field-input-wrapper input,
.field-input-wrapper input.bp4-input {
  width: 100%;
  min-height: 32px;
  padding: 0 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  color: #222;
  outline: 0;
  transition: 0.5s all ease;
}
.field-input-wrapper input:hover,
.field-input-wrapper input.bp4-input:hover {
  border-color: #4baaf2;
  border-right-width: 1px !important;
}
.field-input-wrapper input:active, .field-input-wrapper input:focus,
.field-input-wrapper input.bp4-input:active,
.field-input-wrapper input.bp4-input:focus {
  border-color: #4baaf2;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px rgba(34, 139, 230, 0.2);
  outline: 0;
}
.field-input-wrapper input:disabled,
.field-input-wrapper input.bp4-input:disabled {
  background: #f4f5f6;
  box-shadow: unset;
  outline: 0;
}
.field-input-wrapper input:disabled:hover,
.field-input-wrapper input.bp4-input:disabled:hover {
  border-color: #d9d9d9;
}
.field-input-wrapper select {
  width: 100%;
  min-height: 32px;
  padding: 0 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  color: #222;
  outline: 0;
  transition: 0.5s all ease;
}
.field-input-wrapper select:hover {
  border-color: #4baaf2;
  border-right-width: 1px !important;
}
.field-input-wrapper select:focus {
  border-color: #4baaf2;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px rgba(34, 139, 230, 0.2);
  outline: 0;
}
.field-input-wrapper select:disabled {
  background: #f4f5f6;
  box-shadow: unset;
  outline: 0;
}
.field-input-wrapper select:disabled:hover {
  border-color: #d9d9d9;
}
.field-input-wrapper input.ant-input {
  width: 100%;
  min-height: 32px;
  padding: 0 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  color: #222;
  outline: 0;
  transition: 0.5s all ease;
  border: 0;
}
.field-input-wrapper a {
  text-decoration: none;
}
.field-input-wrapper .ant-input-password {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.field-input-wrapper.is-error {
  border-color: #c00;
}
.field-input-wrapper .bp4-input-ghost {
  border: none;
  min-height: unset;
}
.field-input-wrapper .bp4-input-ghost :hover {
  border-color: unset;
  border-right-width: unset;
}
.field-input-wrapper .bp4-input-ghost:active, .field-input-wrapper .bp4-input-ghost:focus {
  border-color: unset;
  border-right-width: unset;
  box-shadow: unset;
  outline: unset;
}

.field-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.field-horizontal .field-label {
  width: 30%;
}
.field-horizontal .field-input-wrapper {
  width: 70%;
}

.field-error {
  margin: 8px 0 0;
  color: #c00;
  font-size: 12px;
}

.field-extra {
  margin: 8px 0 0;
  color: #6b7580;
  font-size: 12px;
}

.field-tooltip svg {
  color: #abb0b6;
}

.field-postfix {
  float: right;
}
.blue-print-multiselect .bp4-tag-remove {
  color: inherit;
  opacity: 0.5;
}
.blue-print-multiselect .bp4-tag-remove:hover {
  color: inherit;
  opacity: 1;
}
.repeater .item {
  margin: 0 0 20px;
}
.repeater .item:not(:last-of-type) {
  border-bottom: 1px dashed #ddd;
}
.repeater .item.disable-padding {
  padding: 0;
  border: 0;
}
.repeater .item .item-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
}
.repeater .item .item-title {
  margin: 0;
  color: #aaa;
}
.repeater .item.is-condensed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.repeater .item.is-condensed .item-content {
  flex-grow: 1;
  margin-right: 10px;
}
.repeater .add-item-button {
  width: 100%;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
button.bp4-button.select-item {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  max-width: 400px;
}
button.bp4-button.select-item span,
button.bp4-button.select-item svg {
  color: #222;
}
button.bp4-button.select-item .bp4-button-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: wrap;
}
button.bp4-button.select-item.is-disabled span,
button.bp4-button.select-item.is-disabled svg {
  opacity: 0.5;
}
button.bp4-button.select-item.is-active {
  background-color: #e9f3fc;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.cc-select.bp4-button:not(.blueprint-nav .bp4-button) {
  width: 100%;
  min-height: 32px;
  padding: 0 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  color: #222;
  outline: 0;
  transition: 0.5s all ease;
  display: flex;
  padding: 10px 16px !important;
  justify-content: flex-start;
}
.cc-select.bp4-button:not(.blueprint-nav .bp4-button) .bp4-button-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cc-select.bp4-button:not(.blueprint-nav .bp4-button):not(.bp4-disabled):hover {
  border-color: #4baaf2;
  border-right-width: 1px !important;
}
.cc-select.bp4-button:not(.blueprint-nav .bp4-button):not(.bp4-disabled):focus {
  border-color: #4baaf2;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px rgba(34, 139, 230, 0.2);
  outline: 0;
}
.cc-select.bp4-button:not(.blueprint-nav .bp4-button).bp4-disabled {
  background: #f4f5f6;
  box-shadow: unset;
  outline: 0;
}
.cc-select.bp4-button:not(.blueprint-nav .bp4-button).bp4-disabled .cc-select-caret {
  display: none;
}
.cc-select-caret {
  color: #6b7580;
}

.cc-select-list .group-heading {
  display: block;
  color: #8b8b8b;
  margin: 8px 24px;
}
.cc-select-list .group-items .bp4-button {
  padding-left: 48px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.suggest-input .bp4-input {
  width: 100%;
  min-height: 32px;
  padding: 0 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  color: #222;
  outline: 0;
  transition: 0.5s all ease;
  padding: 0 20px;
}
.suggest-input .bp4-input.bp4-active {
  border-color: #125ea0;
  box-shadow: none;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.index-module_upload-table__bh7Nj {
  border-collapse: collapse;
  margin-top: 10px;
}

.index-module_upload-item__R39lg {
  height: 66px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.index-module_upload-img__7nuwB {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.index-module_upload-icon__aElDk {
  font-size: 14px;
}

.index-module_upload-tooltip__ePJSO {
  display: inline-block;
  margin: 5px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.text-area-character-limit {
  width: 100%;
  min-height: 32px;
  padding: 0 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  color: #222;
  outline: 0;
  transition: 0.5s all ease;
  min-height: 64px;
}
.text-area-character-limit:hover {
  border-color: #4baaf2;
  border-right-width: 1px !important;
}
.text-area-character-limit:focus {
  border-color: #4baaf2;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px rgba(34, 139, 230, 0.2);
  outline: 0;
}
.text-area-character-limit:disabled {
  background: #f4f5f6;
  box-shadow: unset;
  outline: 0;
}
.text-area-character-limit:disabled:hover {
  border-color: #d9d9d9;
}

.text-area-character-limit-info {
  padding: 4px 0;
  color: #6b7580;
  font-size: 12px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.action-menu-container .dots:hover > svg {
  color: #64aeee;
}
.action-menu-container .dots.is-open {
  color: #125ea0 !important;
}

.action-menu .bp3-menu-item {
  line-height: 20px;
  align-items: center;
}

.action-menu-item {
  line-height: 12px;
  display: flex;
  align-items: center;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.non-ideal-layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  width: 100%;
}
.non-ideal-layout .body {
  padding-top: 52px;
}
.non-ideal-layout .footer {
  text-align: center;
  margin-top: 24px;
  line-height: 22px;
}

.bp4-non-ideal-state .bp4-non-ideal-state-visual {
  font-size: 80px !important;
}
.bp4-non-ideal-state .bp4-heading {
  color: #222;
}
.bp4-non-ideal-state-text {
  margin-bottom: 24px;
}
.bp4-non-ideal-state-text > span {
  line-height: 22px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.index-module_container__Ua6Zq .bp4-dialog {
  padding-bottom: 0;
  background: #fff;
}
.index-module_container__Ua6Zq .bp4-dialog-header {
  padding: 16px 16px 0;
  box-shadow: unset;
}
.index-module_container__Ua6Zq .bp4-dialog-header > h4 {
  color: #2b3a48;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.index-module_container__Ua6Zq .index-module_content__pHvG0 {
  padding: 16px;
}
.index-module_container__Ua6Zq .index-module_actions__5tVA4 {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}
.index-module_container__Ua6Zq .index-module_actions__5tVA4 button:last-child {
  margin-left: 16px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.ConfirmableAction-module_container__NeXjK .bp4-dialog {
  padding-bottom: 0;
  background: #fff;
}
.ConfirmableAction-module_container__NeXjK .bp4-dialog-header {
  padding: 16px 16px 0;
  box-shadow: unset;
}
.ConfirmableAction-module_container__NeXjK .bp4-dialog-header > h4 {
  color: #2b3a48;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.ConfirmableAction-module_container__NeXjK .ConfirmableAction-module_content__Lwjle {
  padding: 16px;
}
.ConfirmableAction-module_container__NeXjK .ConfirmableAction-module_actions__hEETd {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}
.ConfirmableAction-module_container__NeXjK .ConfirmableAction-module_actions__hEETd button:last-child {
  margin-left: 16px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.template-center .button-group {
  margin-left: auto;
}
.template-center .button-group button {
  margin: 10px;
}
.template-center .bp4-button {
  min-height: 39px;
}
.template-center .bp4-collapse-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.template-center .bp4-collapse-body .details-form {
  width: 30%;
}
.template-center .bp4-collapse-body .details-form .field-vertical {
  margin-top: 20px;
  margin-bottom: 0;
}
.template-center .bp4-collapse-body .details-form .field-extra {
  height: 18px;
  color: #6b7580;
}
.template-center .bp4-collapse-body .details-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  width: 30%;
}
.template-center .bp4-collapse-body .details-info div:nth-child(1) {
  grid-column: 1;
}
.template-center .bp4-collapse-body .details-info div:nth-child(2) {
  grid-column: 2;
}
.template-center .demand-inclusions-section .bp4-tabs {
  background-color: #f0f0f0;
}
.template-center .demand-inclusions-section .bp4-tab-list {
  margin-bottom: 24px;
}
.template-center .demand-inclusions-section .demand-inclusions-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
.template-center .demand-inclusions-section .demand-inclusions-panel p {
  margin-top: 15px;
}
.template-center .no-data {
  background-color: #fff;
}
.loading-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.NoData-module_container__FOkvs .bp4-non-ideal-state {
  padding: 48px;
}
.NoData-module_container__FOkvs .bp4-non-ideal-state, .NoData-module_container__FOkvs .bp4-non-ideal-state > * {
  color: #abb0b6 !important;
  line-height: 22px !important;
}
.NoData-module_container__FOkvs .bp4-non-ideal-state-visual {
  margin-bottom: 0 !important;
}
.input-wrapper {
  position: relative;
}
.input-wrapper input.input {
  position: relative;
  z-index: 5;
}
.input-wrapper input.invisible {
  background-color: transparent;
  color: transparent;
}
.input-wrapper .formatted-value {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  padding: 0 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
.input-wrapper.disabled .formatted-value {
  z-index: 10;
}
.select-list {
  max-height: 400px;
  overflow: scroll;
}

.select-list-empty {
  padding: 20px;
  text-align: center;
}

.select-list-show-more {
  padding: 10px;
  display: flex;
  justify-content: center;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.DrawerForm-module_container__OWsal {
  padding: 24px;
  margin-bottom: 55px;
  overflow-y: scroll;
}

.DrawerForm-module_actions__yYPMP {
  position: absolute;
  z-index: 100;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  gap: 15px;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  border-top: 1px solid #e9e9e9;
  margin: 0;
  background-color: #fff;
}
.fee-preview {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.fee-preview.fee-preview-loading .fee-preview-item {
  color: gray;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.fee-preview .fee-preview-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}
.fee-preview .fee-preview-item {
  min-width: 75px;
  white-space: nowrap;
}
.fee-preview .fee-preview-title {
  font-weight: bold;
  min-width: 125px;
  white-space: nowrap;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}
.user-avatar-globals {
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  padding: 0;
  margin: 0;
}
.user-avatar-globals .avatar {
  flex-shrink: 0;
}
.user-avatar-globals .avatar .ant-avatar-string {
  transform: scale(1) translateX(-50%) !important;
}
.user-avatar-globals .avatar .ant-avatar-sm .ant-avatar-string {
  font-size: 12px;
}
.user-avatar-globals .name {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
/* stylelint-disable selector-pseudo-class-no-unknown, selector-class-pattern */
.DateRangeFilter-module_date-range-filter__roWx- .bp4-datepicker-month-select select,
.DateRangeFilter-module_date-range-filter__roWx- .bp4-datepicker-year-select select {
  width: auto;
  align-items: center;
  padding: auto;
  text-align: center;
  color: #6b7580;
  font-size: 16px;
  font-weight: 400;
}
.DateRangeFilter-module_date-range-filter__roWx- .bp4-datepicker-month-select .bp4-icon.bp4-icon-double-caret-vertical *,
.DateRangeFilter-module_date-range-filter__roWx- .bp4-datepicker-year-select .bp4-icon.bp4-icon-double-caret-vertical * {
  display: none;
  width: 0;
  height: 0;
  flex: none;
  visibility: hidden;
}
.DateRangeFilter-module_date-range-filter__roWx- .DayPicker-Months {
  margin: auto 18px;
  color: #6b7580;
  font-size: 16px;
  font-weight: 400;
}
.DateRangeFilter-module_date-range-filter__roWx- .DayPicker-Weekday {
  color: #6b7580;
  font-size: 16px;
  font-weight: 400;
}
.DateRangeFilter-module_date-range-filter__roWx- .DayPicker-Day {
  width: 25px;
  height: 18px;
  margin: 3px 0;
  color: #6b7580;
  font-size: 16px;
  font-weight: 400;
}
.DateRangeFilter-module_date-range-filter__roWx- .DayPicker-Day.DayPicker-Day--selected-range {
  background-color: #e9f3fc;
}
.DateRangeFilter-module_date-range-filter__roWx- .DayPicker-Day.DayPicker-Day--selected-range-end, .DateRangeFilter-module_date-range-filter__roWx- .DayPicker-Day.DayPicker-Day--selected-range-start {
  background-color: #228be6;
}
.simple-text-filter {
  position: relative;
}
.simple-text-filter .input {
  box-shadow: none;
  outline: none;
  padding-right: 30px;
  width: 300px;
}
.simple-text-filter .search-icon.bp4-button {
  padding: 0 10px;
  min-height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.column-header-wrapper {
  width: 100%;
}
.column-header-wrapper .column-header {
  display: flex;
  align-items: center;
}
.column-header-wrapper .column-header div:not(:first-child) {
  margin-left: 10px;
}
.column-header-wrapper .column-header-title {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
}
.column-header-wrapper .column-header-icon {
  color: #abb0b6;
}
.column-header-wrapper .column-header-icon:hover {
  color: #64aeee;
}
.column-header-wrapper .column-header-icon.active {
  color: #125ea0;
}
.column-header-wrapper .column-header-search-icon {
  font-size: 10px;
}
.column-header-wrapper .column-header-search {
  display: flex;
  height: 16px;
  align-items: center;
}
.column-header-wrapper .column-header-sort {
  font-size: 10px;
  display: flex;
  flex-direction: column;
}
.column-header-wrapper .column-header-sort-asc {
  position: relative;
  top: 1px;
}
.column-header-wrapper .column-header-sort-desc {
  position: relative;
  top: -1px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.data-property.is-in-row {
  margin: 0 20px 20px 0;
}
.data-property-title {
  font-size: 14px;
  color: #434343;
  font-weight: bold;
  margin: 0 0 10px;
}

.pricing-plan:not(:last-child) {
  margin: 0 0 30px;
  padding: 0 0 10px;
  border-bottom: 1px solid #f0f0f0;
}
.pricing-plan:not(:last-child) .ag-theme-alpine {
  margin: 0 0 20px;
}
.pricing-plan-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px;
  color: #6b7580;
  letter-spacing: 0.75px;
}
.pricing-plan-properties {
  display: flex;
  flex-wrap: wrap;
}
.pricing-plan-properties .data-property {
  flex-basis: calc(25% - 40px);
}

.active-contract-card-data-properties {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 20px;
}
.active-contract-card-data-properties .data-property {
  flex-basis: calc(33% - 40px);
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.detail-header {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid #e4e5e7;
  border-bottom: 0;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
}
.detail-header.is-borderless, .detail-header.is-borderless .tabs .bp3-tab-list {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.detail-header .above-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.detail-header .above-title-top-right-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.detail-header .title-wrapper {
  display: flex;
  justify-content: space-between;
}
.detail-header .title {
  color: #000;
  font-size: 24px !important;
  font-weight: 700;
}
.detail-header .supply-subaccount-details .content-above-title {
  margin-bottom: 10px;
}
.header-with-actions {
  display: flex;
  align-items: center;
  height: 63px;
}
.header-with-actions .title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}
.header-with-actions .subtitle {
  font-size: 16px;
  font-weight: bold;
}
.header-with-actions .actions {
  display: flex;
  margin-left: auto;
  padding: 12px 0;
}
.header-with-actions .actions > * {
  margin-left: 16px;
}
.demand-apis-table .change-reason-cell {
  height: 54px;
  line-height: normal;
  display: flex;
  align-items: center;
}
.demand-apis-table .change-reason-cell > div {
  flex-grow: 1;
}
.demand-apis-table .change-reason-cell .ag-cell-value {
  width: 100%;
}
.totaller-widget span {
  margin: 0 5px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.custom-ag-grid .ag-root-wrapper {
  min-height: 25vh;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.audit-history-section {
  margin-top: 24px;
}

.ag-header-cell {
  padding-right: 1px !important;
}
.benchmark-reports-card {
  display: grid;
  text-align: center;
  padding: 40px;
}
.benchmark-reports-card > * {
  justify-self: center;
}
.benchmark-reports-card > p {
  margin-top: 10px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.portal-clipboard {
  cursor: pointer;
}
.portal-clipboard .text-icon-component-text {
  margin: 0 5px 0 0;
}
.portal-clipboard .text-icon-component-icon {
  color: #228be6;
  font-size: 14px;
}
.portal-clipboard .text-icon-component.is-blue-text .text-icon-component-text {
  color: #228be6;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.collapse-card {
  margin-bottom: 10px;
}
.collapse-card .header-title {
  padding: 24px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 0;
  display: grid;
}
.collapse-card .header-title button {
  justify-self: start;
  font-weight: bold;
}
.collapse-card .header-title.button-right {
  grid-template-columns: 3fr 1fr;
}
.collapse-card .header-title.button-right button {
  justify-self: end;
  font-weight: unset;
}
.collapse-card .bp4-collapse {
  margin-top: -2px !important;
}
.collapse-card .bp4-collapse-body[aria-hidden=false] {
  padding: 24px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.collapse-card .bp4-button.bp4-minimal > * {
  color: #2b3a48 !important;
}
.collapse-card .bp4-button:not(.blueprint-nav .bp4-button).bp4-minimal:hover,
.collapse-card .bp4-button:not(.blueprint-nav .bp4-button).bp4-minimal:focus {
  background-color: unset !important;
}
.demand-inclusions-template-modal {
  padding: 35px;
}
.demand-inclusions-template-modal h2 {
  margin-top: 0;
  font-weight: bold;
}
.demand-inclusions-template-modal .button-section {
  display: flex;
  justify-content: end;
}
.demand-inclusions-template-modal .button-section > * {
  margin-left: 10px;
}
.demand-inclusions-template-modal .radio-group-wrapper {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.demand-inclusions-template-modal .radio-group-wrapper .radio-group {
  grid-column: 1/span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.demand-inclusions-template-modal .radio-group-wrapper .radio-group > * {
  font-weight: bold;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.demand-inclusion-templates-list-item h4 {
  font-weight: bold;
  margin: 10px 0;
}
.demand-inclusion-templates-list-item .title-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 8px 24px;
}
.demand-inclusion-templates-list-item .title-content .last-applied > span {
  color: #8f99a8;
}
.demand-inclusion-templates-list-item .title-content .last-applied .outdated {
  padding-left: 24px;
  color: red;
}
.demand-inclusion-templates-list-item .title-content .not-found {
  color: red;
}
.demand-inclusion-templates-list-item .collapse-content {
  margin: 0 auto;
  width: 65%;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.DetailTopBar-module_container__EFRel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
  border-bottom: 1px solid #e4e5e7;
  margin: 0 0 15px;
  margin-top: -18px;
  background: #fff;
}

.self-service-account-upgrade-scope .DetailTopBar-module_container__EFRel {
  background: none;
}

.DetailTopBar-module_back__9lD4W {
  padding: 10px !important;
  color: #228be6 !important;
}

.DetailTopBar-module_actions__6A3uG {
  display: flex;
}
.DetailTopBar-module_actions__6A3uG .button-header:not(:last-child) {
  margin-right: 8px;
}
.supply-sub-account-create-form .heading {
  margin: 30px 0;
}
.supply-sub-account-create-form .description-field {
  width: 100%;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.FreeTrialBanner-module_free-trial-banner__4Y5yK {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 0 16px;
  border: 1px solid #228be6;
  margin-bottom: 30px;
  background: #e3f0ff;
  border-radius: 4px;
}
.FreeTrialBanner-module_free-trial-banner__4Y5yK > span {
  color: #228be6;
}
.FreeTrialBanner-module_free-trial-banner__4Y5yK > span:nth-of-type(1) {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}
.FreeTrialBanner-module_free-trial-banner__4Y5yK > span:nth-of-type(2) {
  font-size: 10px;
  white-space: nowrap;
}
.FreeTrialBanner-module_free-trial-banner__4Y5yK.FreeTrialBanner-module_expiring-soon__FBnSN {
  border: 1px solid #c00;
  background: rgba(204, 0, 0, 0.1);
  color: #c00;
}
.FreeTrialBanner-module_free-trial-banner__4Y5yK.FreeTrialBanner-module_expiring-soon__FBnSN > span {
  color: #c00;
}
.json-container {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.ContactItem-module_container__XuE5q {
  margin-bottom: 10px;
}
.ContactItem-module_container__XuE5q:last-child {
  margin-bottom: 0;
}

.ContactItem-module_title__hkzFI {
  margin-bottom: 10px;
  color: #6b7580;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
}

.ContactItem-module_body__joIo5 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.ContactItem-module_name__kcdu6 {
  margin-bottom: 10px;
  text-align: left;
}

.ContactItem-module_email__Rd5Mx {
  overflow: hidden;
  justify-content: flex-start !important;
  margin-bottom: 10px;
  margin-left: 3px;
}

.ContactItem-module_emailtext__TUdg- {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 14px;
}

.ContactItem-module_icon__xpRCD {
  flex-shrink: 0;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.quick-links-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.quick-links-container h5.bp4-heading {
  margin-top: 32px;
  margin-bottom: 16px;
}
.quick-links-container h6.bp4-heading {
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 22px;
}
.quick-links-container .quick-link-section-item {
  display: block;
  margin-bottom: 16px;
}
.quick-links-container .quick-link-section-item-title {
  margin-left: 8px;
}
.quick-links-support-copy-to-clipboard-button {
  margin-top: 16px;
  display: block;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.ExternalHomeLayout-module_container__hb5wN {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
@media (max-width: 1018px) {
  .ExternalHomeLayout-module_container__hb5wN {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.ExternalHomeLayout-module_title__FooCI {
  margin-bottom: 12px;
  color: #222;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
}

.ExternalHomeLayout-module_description__RykMb {
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
}

.ExternalHomeLayout-module_main__9u-yB {
  width: 70%;
}
@media (max-width: 1018px) {
  .ExternalHomeLayout-module_main__9u-yB {
    width: 100%;
    margin-bottom: 20px;
  }
}

.ExternalHomeLayout-module_sidebar__BOcc1 {
  width: 25%;
}
@media (max-width: 1018px) {
  .ExternalHomeLayout-module_sidebar__BOcc1 {
    width: 90%;
  }
}

.ExternalHomeLayout-module_sidebarlabel__I1NS- {
  height: 19px;
  margin-bottom: 30px;
  color: #434343;
  font: bold 14px/21px, sans-serif;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.detail-layout.default-container,
.detail-layout .panel {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}
.detail-layout .main {
  width: calc(100% - 350px - 40px);
  margin-right: 40px;
}
.detail-layout .sidebar {
  width: 350px;
}
.detail-layout .tabs :global .bp4-tab-list {
  border: 1px solid #e4e5e7;
  border-top: 0;
}
@media (max-width: 1450px) {
  .detail-layout .main {
    width: calc(100% - 250px - 40px);
  }
  .detail-layout .sidebar {
    width: 250px;
  }
}
@media (max-width: 1242px) {
  .detail-layout {
    flex-direction: column;
  }
  .detail-layout .main {
    width: 100%;
    margin-bottom: 20px;
  }
  .detail-layout .sidebar {
    width: 100%;
  }
  .detail-layout .panel {
    flex-direction: column;
    justify-content: center;
  }
}
.anticon {
  display: inline-flex;
  width: 16px;
  height: 16px;
}
.anticon [data-prefix=fak] {
  max-width: 16px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.ant-menu-submenu-popup.ant-menu-dark .ant-menu,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-sub {
  background-color: #222 !important;
}

.cc-side-nav {
  max-width: 240px;
  min-width: 240px;
  height: 100%;
  background-color: #222;
  overflow-y: auto;
  overflow-x: hidden;
}
.cc-side-nav-logo {
  padding: 0 12px;
  pointer-events: none;
  margin: 0;
  display: flex;
  justify-content: left;
  align-items: center;
}
.cc-side-nav-logo-item {
  height: 60px !important;
  margin-bottom: 16px !important;
  padding: 16px 0 !important;
  pointer-events: none;
}
.cc-side-nav-logo-collapsed {
  padding: 0;
  margin: 0;
}
.cc-side-nav .ant-menu-inline-collapsed,
.cc-side-nav .ant-menu.ant-menu-inline,
.cc-side-nav .ant-menu.ant-menu-inline .ant-menu-sub {
  background-color: #222 !important;
}
.cc-side-nav .ant-menu-item-group-title {
  padding-top: 44px;
  font-weight: 700;
  color: #fff !important;
}
.cc-side-nav .ant-menu-item-group-title:empty {
  display: none;
}
.cc-side-nav.is-collapsed {
  max-width: 80px;
  min-width: 80px;
  width: 80px;
}
.cc-side-nav.is-collapsed .ant-menu-inline-collapsed {
  width: 100% !important;
}
.cc-side-nav.is-collapsed .ant-menu-item-group-title {
  visibility: hidden !important;
}
.cc-side-nav.is-collapsed .ant-menu-item,
.cc-side-nav.is-collapsed .ant-menu-submenu-title {
  padding: 0 !important;
  text-align: center;
}
.cc-side-nav.is-collapsed .cc-side-nav-logo-item {
  line-height: 60px;
  margin: auto;
  height: 60px !important;
  margin-bottom: 10px !important;
}
.cc-side-nav.is-collapsed .cc-side-nav-logo-item .cc-side-nav-logo {
  justify-content: center;
  font-size: 12px;
  margin: 12px 0;
  height: auto !important;
  padding: 6px !important;
}

.portal-sider-menu.portal-sider-menu-collapsed .fa-engine-control-center-logo-vertical {
  color: #fff;
}
.portal-sider-menu.portal-sider-menu-collapsed .anticon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  margin-left: 5px !important;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.perf-report-card-item.container {
  width: 25%;
  padding: 0 40px;
}
.perf-report-card-item.container:not(:last-of-type) {
  border-right: 1px solid #ddd;
}
.perf-report-card-item.container .icon {
  margin-bottom: 15px;
  margin-left: -5px;
  font-size: 40px;
}
.perf-report-card-item.container .icon .fa-filter-circle-dollar {
  max-width: 24px;
}
.perf-report-card-item.container .value {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}
.perf-report-card.container {
  display: flex;
  padding: 32px 0;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.perf-report-card.header {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.perf-report-card.header > * {
  font-weight: bold;
}
.perf-report-card.header, .perf-report-card.content {
  display: flex;
}
.perf-report-card.header > *, .perf-report-card.content > * {
  width: 25%;
  padding: 18px 24px;
}
.perf-report-card.header > *:not(:last-of-type), .perf-report-card.content > *:not(:last-of-type) {
  border-right: 1px solid #ddd;
}
.perf-report-card.select {
  width: 300px;
  display: block;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.index-module_portal-selectable-card__TKcRf {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  text-align: center;
  width: 20vw;
  height: 20vw;
  min-height: 145px;
  min-width: 160px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  display: grid;
  align-items: center;
  margin: 8px;
  position: relative;
}
.index-module_portal-selectable-card-small__AXs8G {
  width: calc(16% - 20px);
  max-width: 250px;
  height: 11vw;
}
.index-module_portal-selectable-card-large__8pem3 {
  width: 25vw;
  height: 23vw;
}
.index-module_portal-selectable-card__TKcRf img {
  width: 90%;
}
.index-module_portal-selectable-card__TKcRf:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.index-module_portal-selectable-card__TKcRf .index-module_card-icon__CaLgQ {
  color: #228be6;
  font-size: 40px;
  margin: 5px auto;
}
.index-module_portal-selectable-card-locked__o9UFM {
  opacity: 0.5;
}
.index-module_portal-selectable-card-locked__o9UFM .index-module_card-icon__CaLgQ {
  color: #6b7580;
}
.index-module_portal-selectable-card-selected__6aV30 {
  border: 1px solid #228be6;
  box-shadow: 0 14px 14px rgba(35, 124, 241, 0.2);
}
.index-module_portal-selectable-card-extra__g9yTd {
  position: absolute;
  right: 18px;
  top: 18px;
  color: #6b7580;
}

.index-module_card-select-input__qb3JK {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 15px 0;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.product-type-add-field-item {
  padding: 20px;
  border: 1px solid #eeeff1;
  border-radius: 5px;
}

.product-type-add-field-item.is-collapsed {
  background-color: #eeeff1;
  padding: 20px;
  cursor: pointer;
  transition: 0.5s all ease;
}
.product-type-add-field-item.is-collapsed:hover {
  background-color: #f9f9f9;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.PropertyItem-module_container__CjlB2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.PropertyItem-module_title__Xtqh8 {
  margin-bottom: 10px;
}

.PropertyItem-module_body__GsFPM {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.PropertyItem-module_copy-icon__KKNJ3 {
  color: #125ea0;
}
.second-look-offer-configuration-card > .body {
  padding: 24px;
}
.even-status {
  display: flex;
  font-size: 12px;
}
.even-status-icon {
  display: flex;
  align-items: center;
  margin-right: 4px;
}
.key-value-input {
  margin: 0;
  width: 50%;
}
.key-value-input:not(:last-of-type) {
  margin-right: 20px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.SupplyContractsList-module_supply-contracts-list__z4lzr {
  padding: 0 24px;
  font-size: 12px;
  line-height: 18px;
}
.SupplyContractsList-module_supply-contracts-list__z4lzr table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.SupplyContractsList-module_supply-contracts-list__z4lzr table thead th {
  padding: 0;
}
.SupplyContractsList-module_supply-contracts-list__z4lzr table tbody tr {
  border-bottom: 1px solid #e4e5e7;
}
.SupplyContractsList-module_supply-contracts-list__z4lzr table tbody tr:last-of-type {
  border-bottom-color: transparent;
}
.SupplyContractsList-module_supply-contracts-list__z4lzr table td {
  font-weight: 400;
}
.SupplyContractsList-module_supply-contracts-list__z4lzr table td:first-child {
  padding: 24px;
  padding-left: 0;
}
.SupplyContractsList-module_supply-contracts-list-tag__4TOOE {
  margin-top: 4px !important;
}
.UpgradeBanner-module_upgrade-banner__cxbrI {
  margin: 20px 0;
}
.UpgradeBanner-module_upgrade-banner-text__Zk9aI {
  margin-top: -16px;
  font-size: 12px;
  white-space: normal;
}
.stats-title {
  font-weight: bold;
  font-size: 18px;
}

.stats-list {
  display: flex;
  flex-direction: row;
}

.stat-item {
  margin-right: 80px;
}
.stat-item p {
  text-align: center;
}
.stat-item p:first-child {
  font-weight: bold;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.details-form {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}
.details-form.left-align {
  margin-left: 0;
  margin-right: 0;
}
.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  align-items: center;
  justify-content: center;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.upload-container .error-message {
  margin: 8px 0 0;
  color: #c00;
  font-size: 12px;
}
.upload-container .error-message-container {
  margin: 5px 0;
}
.upload-container .info-message {
  margin: 0 0 8px;
  color: #8c8c8c;
  font-size: 12px;
}
.upload-container .or-container {
  display: flex;
  align-self: center;
  margin: 5px 0;
}
.offer-version {
  margin-bottom: 20px;
}
.offer-version:last-of-type {
  margin-bottom: 0;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.performance-summary-splash-screen {
  padding-bottom: 1em;
  text-align: center;
}
.performance-summary-splash-screen .logo {
  padding-top: 3em;
}
.performance-summary-splash-screen .title {
  padding-bottom: 1em;
  color: #000;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}
.performance-summary-splash-screen .body {
  padding-bottom: 1em;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.ag-theme-alpine.custom {
  padding: 24px;
  background: #f4f5f6;
  font-size: 14px;
}
.ag-theme-alpine.custom .grouped-elements {
  background: #eeeff1;
}
.ag-theme-alpine.custom .grouped-elements.hide-expand-arrow .ag-group-contracted {
  display: none;
}
.ag-theme-alpine.custom .box {
  display: inline-block;
  width: 50%;
  padding: 10px;
  border: 1px solid black;
}
.ag-theme-alpine.custom .box + button {
  margin: 20px;
}
.ag-theme-alpine.custom .header-section {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 32px;
}
.ag-theme-alpine.custom .header-section h3 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
.ag-theme-alpine.custom .header-section .button-group button {
  float: right;
  margin-left: 16px;
}
.ag-theme-alpine.custom .ag-header-cell:not(.ag-header-cell-sortable).ag-react-container.column-header.column-header-search {
  display: none;
}
.ag-theme-alpine.custom .ag-header-cell {
  padding-left: 18px;
  padding-right: 18px;
}
.ag-theme-alpine.custom .selectors {
  display: flex;
  flex-flow: wrap;
}
.ag-theme-alpine.custom .selectors .bp4-input {
  min-height: 38px;
  padding: 0 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  color: #434343;
  outline: 0;
  transition: 0.5s all ease;
}
.ag-theme-alpine.custom .selectors .bp4-control-group .bp4-input-group:first-child > input {
  border-radius: 4px 0 0 4px;
}
.ag-theme-alpine.custom .selectors .bp4-control-group .bp4-input-group:last-child > input {
  border-radius: 0 4px 4px 0;
}
.ag-theme-alpine.custom .selectors > section {
  margin: 0 16px 25px 0;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.sign-in-page {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 20px;
}
.sign-in-page .sign-in-box {
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  min-width: 450px;
  padding: 60px 40px;
}
.sign-in-page .welcome-text {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 40px;
}
.sign-in-page .okta-sign-in-button {
  background-color: #1d2f54;
  color: #fff;
  font-size: 16px;
  height: 60px;
  width: 100%;
}
.sign-in-page .okta-sign-in-button:hover {
  background-color: #2a447a;
}
.sign-in-page .footer {
  border-top: 1px solid #d9d9d9;
  margin: 40px 0 0;
  padding: 40px 0 0;
  text-align: center;
}
.template-center-landing-page-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.demand-inclusion-template-table .user-avatar-globals {
  padding: 0;
}
.demand-inclusion-template-table .ag-header-cell {
  padding-left: 17px;
  padding-right: 17px;
}
.demand-inclusion-template-table .ag-row .ag-cell {
  padding-left: 17px;
  padding-right: 17px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.CancelSubscriptionAction-module_cancel-subscription-action__hjdU1 {
  display: flex;
  min-height: 300px;
  flex-direction: column;
  font-family: "Open Sans";
}
.CancelSubscriptionAction-module_cancel-subscription-action__hjdU1 h4 {
  margin-top: 0;
  color: #2b3a48;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
}
.CancelSubscriptionAction-module_cancel-subscription-action__hjdU1 p {
  color: #2b3a48;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
}
.CancelSubscriptionAction-module_cancel-subscription-action__hjdU1 .CancelSubscriptionAction-module_checkbox__SU1Vo {
  padding: 24px !important;
  padding-top: 14px !important;
  padding-left: 36px !important;
}
.CancelSubscriptionAction-module_cancel-subscription-action__hjdU1 .CancelSubscriptionAction-module_radio-btn__hAC42 {
  padding-left: 36px !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
}
.CancelSubscriptionAction-module_cancel-subscription-action__hjdU1 .CancelSubscriptionAction-module_progress__89EHl {
  margin: auto;
  margin-bottom: 0;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.index-module_demand-contract-rd__OpPzh {
  padding: 32px;
}
.index-module_demand-contract-rd-title__bVtQ7 {
  margin-bottom: 24px;
}
.index-module_demand-contract-rd-title__bVtQ7 h6 {
  margin: 0;
  color: #6b7580;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}
.index-module_demand-contract-rd-label__wrpU5 {
  color: #2b3a48;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}
.index-module_demand-contract-rd-content-grid__AsjRi {
  display: grid;
  row-gap: 16px;
}
.index-module_demand-contract-rd-row__SPCfC {
  display: grid;
  grid-template-columns: 232px auto;
}
.index-module_demand-contract-rd-details__ma8lR {
  row-gap: 4px;
}
.index-module_demand-contract-rd-details-content__iju4P div {
  font-size: 14px;
  line-height: 22px;
}
.index-module_demand-contract-rd-subgrid__6kOno {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.index-module_demand-contract-rd-subgrid2__o-n7d {
  display: grid;
  row-gap: 8px;
}
.index-module_demand-contract-rd-subgrid2__o-n7d .index-module_demand-contract-rd-row__SPCfC div {
  font-size: 14px;
  line-height: 22px;
}
.index-module_demand-contract-rd-not-handled__oAqQ4 {
  margin: 0;
}
.DemandUserSettingsForm-module_container__H9aMS {
  width: 75%;
  margin: 0 auto;
}

.DemandUserSettingsForm-module_submit__0Ai11 {
  display: flex;
  justify-content: flex-end;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.DemandUserDetailsTab-module_container__nI-pB {
  border: 1px solid #e4e5e7;
  background: #fff;
  border-radius: 4px;
}

.DemandUserDetailsTab-module_form-container__DQF6p {
  padding: 24px;
}

.DemandUserDetailsTab-module_header__8i0xI {
  padding: 16px 24px;
  border-bottom: 1px solid #e4e5e7;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.FirstIntegrationCard-module_first-integration-card__q5JNa {
  text-align: center;
}
.FirstIntegrationCard-module_first-integration-card-content__8ygDa {
  padding: 80px 0;
}
.FirstIntegrationCard-module_first-integration-card-button__-FiAL {
  height: 100%;
  padding: 10px 16px;
}
.FirstIntegrationCard-module_first-integration-card-message__aiU9K {
  max-width: 30rem;
  margin: auto auto 30px;
}
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.adjustment-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  height: 32px;
}
.adjustment-form-header h3 {
  margin: 0;
  color: #989898;
  font-weight: 500;
  font-size: 16px;
}
.adjustment-form-append {
  width: 100%;
  margin-bottom: 16px;
}
.adjustment-form-amount, .adjustment-form-new-total {
  position: relative;
}
.adjustment-form-amount > span, .adjustment-form-new-total > span {
  position: absolute;
  left: 10px;
  top: 7px;
}
.adjustment-form-amount input[type=number], .adjustment-form-new-total input[type=number] {
  padding-left: 24px;
  text-align: left;
}
.adjustment-form-amount {
  display: flex;
  position: relative;
}
.adjustment-form-amount .bp4-button-group {
  margin-left: 8px;
}
.adjustment-form-new-total-wrapper {
  justify-content: flex-end;
}
.access-token-create-form-token-types .field-input-wrapper {
  display: flex;
  flex-direction: column;
}
.access-token-create-form-token-types label {
  display: flex;
  align-items: center;
}
.access-token-create-form-token-types label span {
  padding: 0 8px;
}
.access-token-create-form-token-types input {
  width: 14px;
  height: 14px;
}
.custom-offer-form {
  width: 75%;
  margin: 0 auto;
}
.custom-offer-form .submit {
  display: flex;
  justify-content: flex-end;
}
.first-integration-form-container .form-row {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.first-integration-form-container .form-field {
  width: 48%;
  min-width: 325px;
  margin-bottom: 25px;
}
.first-integration-form-container .actions {
  display: grid;
  justify-content: center;
}
.first-integration-form-container .icon {
  font-size: 14px;
}
.first-integration-form-container .action-button {
  margin: 10px;
}
.UserAccessForm-module_container__ec-K1 {
  width: 75%;
  margin: 0 auto;
}

.UserAccessForm-module_submit__RLXAn {
  float: right;
}
.us-states-form .bp4-tag-remove {
  color: inherit;
  opacity: 0.5;
}
.us-states-form .bp4-tag-remove:hover {
  color: inherit;
  opacity: 1;
}
.us-states-form .clipboard {
  margin-bottom: 10px;
}
.us-states-form .clipboard .portal-clipboard {
  display: inline-block;
}
.us-states-form .bp4-tag {
  font-size: 14px;
  margin: 0 0 5px 5px;
}
.us-states-form .enabled-states-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.us-states-form .enabled-states-list-total {
  width: 30%;
  font-size: 22px;
}
.us-states-form .enabled-states-list-total-label {
  font-size: 16px;
  font-weight: 500;
}
.us-states-form .enabled-states-list-items {
  width: 70%;
}
.us-states-form .enabled-states-list-items-label {
  font-size: 16px;
  font-weight: 500;
}
/* stylelint-disable no-descending-specificity */
/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */
/* GENERAL STYLES */
/*** COLORS ***/
/* TRANSPARENT COLORS */
/* SOLID COLORS */
/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */
/*** Blueprint Overrides ***/
.subscription-plan-top-button {
  margin-bottom: 25px;
}

.subscription-plan {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 30px;
  border: 1px solid #e5e5e5;
  margin-top: 35px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 11px rgba(34, 139, 230, 0.161);
  text-align: center;
}
.subscription-plan-header {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;
}
.subscription-plan-header::before {
  position: absolute;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 6px 6px 0 0;
  content: "";
}
.subscription-plan-header-icon {
  margin-top: -35px;
  margin-bottom: -10px;
}
.subscription-plan-triangle path {
  fill: currentcolor;
}
.subscription-plan.starter .subscription-plan-header::before {
  background-color: #64aeee;
}
.subscription-plan.starter .subscription-plan-header-icon {
  color: #64aeee;
}
.subscription-plan.starter .subscription-plan-triangle {
  color: #64aeee;
}
.subscription-plan.growth .subscription-plan-header::before {
  background-color: #228be6;
}
.subscription-plan.growth .subscription-plan-triangle {
  color: #228be6;
}
.subscription-plan.growth .subscription-plan-header-icon {
  color: #228be6;
}
.subscription-plan.scale .subscription-plan-header::before {
  background-color: #125ea0;
}
.subscription-plan.scale .subscription-plan-header-icon {
  color: #125ea0;
}
.subscription-plan.scale .subscription-plan-triangle {
  color: #125ea0;
}
.subscription-plan-subheader {
  padding: 0 24px;
}
.subscription-plan-features {
  padding: 0 24px;
  margin-bottom: 20px;
  list-style: none;
}
.subscription-plan-features > li {
  position: relative;
  padding: 13px 0;
  border-top: 1px solid #e9e9e9;
}
.subscription-plan-features > li .label {
  margin: 5px 0;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
.subscription-plan-features > li .label span {
  max-width: 150px;
}
.subscription-plan-features > li .value {
  height: 24px;
  margin: 0;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
}
.subscription-plan-features > li svg {
  vertical-align: middle;
}
.subscription-plan.unavailable {
  filter: grayscale(1);
}
.subscription-plan.unavailable .subscription-plan-header::before,
.subscription-plan.unavailable .subscription-plan-header-icon svg,
.subscription-plan.unavailable .subscription-plan-triangle,
.subscription-plan.unavailable .subscription-plan-subheader,
.subscription-plan.unavailable .subscription-plan-features {
  opacity: 0.5;
}
.subscription-plan.unavailable button {
  visibility: hidden;
}
.subscription-plan-name {
  padding-top: 33px;
  margin-bottom: 0;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.subscription-plan-info {
  padding: 20px 0;
}
.subscription-plan-info:not(:first-of-type) {
  border-top: 1px solid #e9e9e9;
}
.subscription-plan-value {
  height: 27px;
  margin-bottom: 5px;
  color: #434343;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}
.subscription-plan-subvalue {
  height: 20px;
  margin-bottom: 5px;
  color: #434343;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}
.subscription-plan-subvalue.two-lines {
  height: 44px;
}
.subscription-plan-subvalue-small {
  min-height: 17px;
  margin-bottom: 0;
  color: #434343;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}
.subscription-plan-label {
  min-height: 17px;
  margin-top: 0;
  margin-bottom: 0;
  color: #434343;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}
.subscription-plan-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px;
}
.subscription-plan-activate-button {
  margin: 0 auto;
  padding: 0 24px !important;
  font-size: 12px;
}
/*# sourceMappingURL=styles.css.map */