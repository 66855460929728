.notification-item {
  &.clickable {
    cursor: pointer;
  }

  .ant-notification {
    position: relative;
    width: 100%;
    margin: 0;
  }

  .ant-notification-notice {
    width: 100%;
    margin: 0;
  }

  .ant-notification-notice-message {
    display: block;
    width: auto;
  }

  .ant-notification-notice-close {
    right: 24px;

    &.read {
      color: $theme-blue;

      &:hover {
        color: rgba($theme-blue, 0.6);
      }
    }

    &.disabled {
      color: $silver;
      pointer-events: none;
    }
  }
}

.notification-item-time {
  text-align: right;
  color: $theme-gray-8;
  font-style: italic;
  margin-top: 0.25em;
  margin-bottom: 1em;
}
