.hex-color-input {
  display: flex;

  &-preview {
    height: 32px;
    width: 32px;
    border: 1px solid $border-color-base;
    border-right: none;
    border-radius: 4px 0 0 4px;
  }

  &-form {
    width: 100px;
    border-radius: 0 4px 4px 0;
    z-index: 4;
  }
}
