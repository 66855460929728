@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

// components styles
@import "~@portal/ui-lib/src/styles/app";

// componenet module styles
@import "~@portal/ui-lib/dist/css/styles";

// portal-app styles
@import "../components/components-styles";
@import "../containers/containers-styles";
@import "../pages/pages-styles";
@import "../resources/resources-styles";
