.plan-benefits-card {
  background: $white-smoke !important;
  border: 1px solid #eaebec !important;
  max-width: 400px;

  h3 {
    color: $theme-gray-7;
    font-weight: 600;
  }

  .benefit-group {
    margin-top: 15px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    > * {
      margin-bottom: 5px;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    h4 {
      font-weight: bold;
    }

    p {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }
}
