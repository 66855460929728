.supply-subaccount-card-scope {
  .card {
    border-radius: 6px;
    margin: 0 0 20px;

    &:last-of-type {
      margin: 0 0 40px;
    }

    &-stats {
      border: 0;
      margin: 0 -10px 0 -20px !important;
      padding: 0 !important;
    }

    &-partner-keys {
      padding: 16px 0;
    }
  }
}
