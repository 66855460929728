.self-service-custom-offers {
  .self-service-custom-offers-header {
    background: $theme-white;
    border-top: 1px solid $border-color-base;
    padding: $margin-lg;
    padding-bottom: 0;

    h1 {
      font-size: 22px;
      font-weight: $font-weight-bold;
      margin: 0;
      padding-top: 16px;
      padding-bottom: 12px;
    }
  }

  .contact-us-text {
    margin-bottom: 15px;
    display: inline-block;
  }

  .contact-us-form-wrapper {
    max-width: 900px;
    margin: auto;

    .portal-form-actions {
      button {
        float: right;
      }
    }
  }

  .ant-tabs-nav,
  .ant-tabs-content-holder {
    padding: 0 $padding-lg;
    margin-bottom: $padding-lg !important;
  }

  .ant-tabs-tab {
    padding: 10px 0 !important;

    &-btn {
      font-size: 14px;
      letter-spacing: 0.56px;

      &[aria-selected="false"] {
        color: $theme-gray-6;
      }
    }
  }

  .ant-tabs-nav {
    background: $theme-white;
  }

  .sidebar-content {
    .quick-links-container {
      nav:first-of-type {
        h5 {
          margin-top: 56px;
        }
      }
    }
  }
}
