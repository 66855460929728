.self-service-account-scope {
  .self-service-account-header {
    background: $theme-white;
    border-top: 1px solid $border-color-base;
    padding: $margin-lg;
    padding-bottom: 0;

    .ant-tag {
      color: $theme-gray-9;
      border: 1px solid $snowbank;
      padding: 4px 8px;
      padding-bottom: 3px;
    }

    h1 {
      font-size: 22px;
      font-weight: $font-weight-bold;
      margin: 0;
      padding-top: 16px;
      padding-bottom: 12px;
    }
  }

  .ant-tabs-nav,
  .ant-tabs-content-holder {
    padding: 0 $padding-lg;
    margin-bottom: $padding-lg !important;
  }

  .ant-tabs-tab {
    padding: 10px 0 !important;

    &-btn {
      font-size: 14px;
      letter-spacing: 0.56px;

      &[aria-selected="false"] {
        color: $theme-gray-6;
      }
    }
  }

  .ant-tabs-nav {
    background: $theme-white;
  }
}
