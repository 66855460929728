.call-button {
  background: $theme-white;
  border: $theme-white;
  color: $theme-blue;
  margin: auto 0;
  font-weight: $font-weight-semibold;
  filter: drop-shadow(0 0 5px rgba($theme-blue, 0.7));
  border-radius: 20px;
  line-height: 1;
  padding: 10px 20px;
}
