.hex-color-input {
  display: flex;

  input[type="color"] {
    height: 32px;
    width: 32px;
    border: 1px solid $border-color-base;
    border-right: none;
    border-radius: 4px 0 0 4px;
    flex-shrink: 0;
    outline: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 4px 0 0 4px;
  }

  input[type="color"]::-moz-color-swatch {
    border: none;
    border-radius: 4px 0 0 4px;
  }

  &-form {
    max-width: 100%;
    border-radius: 0 4px 4px 0;
    z-index: 4;
  }
}
