.top-totals-panel-container {
  .ant-col {
    flex-basis: 0;
    flex-grow: 1;
    margin-bottom: $margin-lg;
  }

  .top-totals-panel-item {
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      box-shadow: none;
      border-color: $border-color-base;
    }

    &.active {
      box-shadow: none;
      filter: drop-shadow(0 0 5px rgba($theme-blue, 0.7));

      &::after {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        background-color: $theme-white;
        position: absolute;
        border-bottom: 1px solid $border-color-base;
        border-right: 1px solid $border-color-base;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      path:not([fill]) {
        fill: $theme-blue;
      }
    }

    &.has-icon {
      .ant-statistic {
        margin-left: 30px;

        .ant-statistic-content-prefix {
          position: absolute;
          left: 24px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .ant-statistic-title {
      span.has-tooltip {
        border-bottom: 1px dotted;
      }
    }

    .ant-statistic-content-value {
      font-weight: bold;
    }
  }
}
