.partners-tab {
  background-color: $theme-white;
  border: 1px solid $cold-morning;
  border-radius: 6px;

  .approve-button-confirmable {
    display: inline;

    .approve-button {
      margin-left: $margin-lg;
    }
  }

  .lender-status {
    .tooltip-icon {
      font-size: 18px;

      svg {
        fill: $theme-blue;
      }
    }
  }

  .even-table-action-bar {
    padding: 16px $padding-lg;
    margin-bottom: 0;
  }

  .even-table-subtitle {
    padding: 0 $padding-lg;
    padding-bottom: 16px;
    margin-top: -$padding-lg;
  }

  .even-table {
    border-top: 1px solid $cold-morning;
    padding: 0 16px;

    th.ant-table-cell {
      padding: 0 !important;
      border-bottom: 0 !important;
    }
  }
}
