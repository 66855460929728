.integration-expanded-row {
  display: flex;
  flex-direction: row;

  &-content {
    width: 100%;
    padding-right: $margin-lg;
  }

  &-action {
    white-space: nowrap;
  }

  &-title,
  &-subtitle {
    font-size: 12px;
    color: $theme-gray-9;
  }

  &-title {
    font-weight: $font-weight-bold;
  }

  &-action-button {
    &:disabled {
      opacity: 0.5 !important;
      background-color: $theme-blue !important;
      color: $theme-white !important;
    }
  }

  .implementaion-fee-waived-tag {
    font-weight: normal !important;
    margin-bottom: 12px;
  }
}
