.lead-filter-rule-item {
  .field-attribute-type,
  .field-attribute-condition,
  .field-attribute-value {
    margin-bottom: $input-gutter !important;
  }

  .field-checkbox {
    margin-bottom: 5px;
  }
}
