.audit-trail-tab {
  .ant-table-wrapper {
    &.audit-trail-tab-error-log-table {
      .ant-table-cell {
        padding: 8px;
        text-align: left;
      }

      .ant-table-cell:nth-child(2) {
        white-space: nowrap;
        padding-right: 2rem;
      }
    }
  }

  &-audit-trail-panel {
    .ant-collapse-content-box {
      padding-top: 0;
    }

    .ant-table-wrapper {
      &.audit-trail-tab-audit-trail-table {
        /* stylelint-disable no-descending-specificity */
        .ant-table-cell {
          padding: 8px;
          text-align: center;
        }
        /* stylelint-enable no-descending-specificity */
        .ant-table-cell:first-child {
          text-align: left;
        }
      }
    }

    .ant-collapse-content > .ant-collapse-content-box {
      .ant-table-wrapper {
        border-top: 0;
      }
    }
  }

  &-error-log-collapse {
    border-radius: 0;
    border-top: 0;
  }

  &-error-log-panel {
    .ant-collapse-content > .ant-collapse-content-box {
      .ant-table-wrapper {
        border: 0;
      }
    }
  }

  .ant-collapse-content > .ant-collapse-content-box {
    .ant-table-wrapper {
      border: 1px solid $border-color-base;
      border-radius: 0;
      overflow: auto;
    }
  }

  .ant-collapse-header {
    font-size: 12px;
    color: $theme-gray-9;
    font-weight: bold;
  }

  .ant-table-content {
    font-size: 12px;
  }

  th {
    .ant-table-filter-trigger-container {
      position: relative;
    }

    &.ant-table-column-has-sorters {
      .ant-table-filter-column-title {
        padding-right: 0 !important;
        flex-grow: 0;
      }
    }
  }
}
