/* Sync theme values with enum ThemeColor in even-ts-static/lib/theme.ts for components that require inline styling */

/* GENERAL STYLES */

// styles
$font-family: "Open Sans", sans-serif;
$font-weight-bold: 700;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$input-gutter: 10px;
$sider-width: 250px;

// Breakpoints
// Adding sider with to account for breakpoint.
$breakpoint-xs: 576px + $sider-width;
$breakpoint-sm: 768px + $sider-width;
$breakpoint-md: 992px + $sider-width;
$breakpoint-lg: 1200px + $sider-width;

/*** COLORS ***/

$okta-blue: #1d2f54;

/* TRANSPARENT COLORS */
$black-75pcnt: rgb(0 0 0 / 75%);
$gray-50pcnt: rgb(206 217 224 / 50%);

/* SOLID COLORS */
// canonical colors
$black: #000;
$white: #fff;
$theme-black: $black;
$theme-white: $white;

// theme-grays
$theme-gray-11: #222;
$theme-gray-9: #434343;
$theme-gray-8: #707070;
$theme-gray-7: #6b7580;

// mid grey
$theme-gray-6: #8c8c8c; // 10
$wild-dove: #8b8b8b; // once
$spanish-grey: #989898; // used 7 times
$cistern: #abb0b6; // used 8 times
$trance: #8f99a8; // used once
$hydrargyrum: #9b9b9b; // used twice

// light greys
$theme-gray-5: #a5a5a5;
$peace: #a3b1bf;
$robo-master: #adadad;
$silver: #bfbfbf;
$silverback: #cbcbcb;
$cerebral-grey: #ccc;
$saltwater: #c1cfde;
$gainsboro: #dcdcdc;

// off-whites
$theme-gray-4: #f0f0f0;
$theme-gray-3: #f3f3f3;
$mercury: #ebebeb;
$cotton-ball: #f2f8fd;
$snowbank: #e9e9e9;
$steam: #ddd;
$kingly-cloud: #dfdfdf;
$lynx-white: #f7f7f7;
$lilac-mist: #e4e5e7;
$snowflake: #eeeff1;
$unicorn-silver: #e8e8e8;
$white-smoke: #f4f5f6;
$white-solid: #f4f4f8;
$doctor: #f9f9f9;
$moon-white: #e9f3fc;
$sheer-peach: #fff7e6;
$orochimaru: #d9d9d9;
$clear-skies: #e6f7ff;
$cold-white: #f0fffa;
$cold-morning: #e5e5e5;
$cascading-white: #f6f6f6;

// reds
$theme-red: #c00;

// oranges
$theme-orange: #f90;
$cornmeal: #ffd591;
$messinesi: #fee2bd;

// greens
$temp-green-1: #097756;
$theme-green-6: #01917e;
$temp-green-2: #0ca678; // used once
$theme-green: #0bb89b; // used a lot
$aare-river: #00bc9a; // used once
$temp-green-3: #55c1a1; // used once
$mentos: #7cd8c9;

// blues
$ships-officer: #2b3a48; // used 15x
$theme-blue: #228be6;
$daphne: #125ea0;
$celtic-blue: #36c;
$janitor: #2364d3;
$clear-chill: #1890ff;
$adamantine-blue: #4baaf2;
$blue-jeans: #64aeee;
$theme-blue-light: #6bcef6;
$long-island-sound: #91d5ff;
$dithered-sky: #c2ddfb;
$theme-blue-5: #e3f0ff;

// Names generated by https://colornamer.robertcooper.me/
// refactor these colors in the future!

/*
* The following styles should align with the styles in the antd-theme.json
 * file in the portal-app root:
 */

// styles
$border-radius-base: 4px;
$default-border-style: 1px solid $lilac-mist;
$heading-1-size: 32px;
$heading-2-size: 24px;
$heading-3-size: 20px;
$margin-lg: 20px;
$padding-lg: 24px;

// colors
$border-color-base: $orochimaru;
$component-background: $theme-white;
$info-color: $theme-blue;
$link-color-on-active: $daphne;
$link-color-on-hover: $blue-jeans;
$link-color-on-visited: $daphne;
$link-color: $theme-blue;
$menu-dark-bg: $theme-gray-11;
$primary-color: $theme-blue;
$text-color: $theme-gray-11;

/*** Blueprint Overrides ***/

// Blueprint overriding only applies partially to some elements
$blue2: $theme-blue;
$blue3: $theme-blue;
$pt-intent-primary: $theme-blue;

// Button intent success
$success-btn-active-color: $temp-green-1;
$success-btn-default-color: $temp-green-2;
$success-btn-hover-color: $temp-green-3;

// Button intent primary
$checkbox-active-color: $theme-blue;
$primary-btn-active-color: $daphne;
$primary-btn-default-color: $theme-blue;
$primary-btn-hover-color: $blue-jeans;
$primary-btn-disabled-color: $blue-jeans;
$primary-btn-outlined-default-color: $lilac-mist;
$primary-btn-outlined-text-color: $ships-officer;
$radio-active-color: $theme-blue;
$background-disabled: $gray-50pcnt;

// Misc
$primary-dot-default-color: $lilac-mist;
$primary-dot-active-color: $theme-blue;
