.carousel-section-carousel-wrapper {
  .welcome-tutorial-carousel-item {
    display: flex !important;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    button {
      margin-top: 50px;
      height: 45px;
      bottom: 20px;
      padding: 6.4px 20px;
      font-size: 16px;
    }

    &-first-title {
      color: $theme-white !important;
      margin: 60px 0 !important;
      text-align: center;
    }

    &-title {
      color: $theme-white !important;
      margin-bottom: 40px !important;
      text-align: center;
      font-weight: 500 !important;

      span {
        display: block;
        font-size: 25px;
        padding: 15px;
      }
    }

    &-paragraph {
      color: $border-color-base !important;
      margin-bottom: 20px !important;
      font-size: 16px !important;
      width: 80%;
      text-align: center;
    }

    &-footnote {
      color: $theme-white !important;
      margin-bottom: 20px !important;
      font-size: 16px !important;
      width: 80%;
      text-align: center;
    }

    &-image-wrapper {
      width: 70%;

      .carousel-image {
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: $border-radius-base;
      }

      > span > span {
        padding-top: calc(100% - 100px) !important;
      }
    }
  }

  .ant-carousel {
    height: 80%;

    .slick-dots li {
      height: 9px;
      width: 9px;
      background-color: $theme-gray-7;
      border-radius: 50%;
      transition: all 0.9s;
      margin: 0 8px;
    }

    .slick-dots li.slick-active {
      background-color: $theme-blue;
      height: 11px;
      width: 11px;
    }
  }
}
