.partner-page-head {
  background-color: $theme-white;
  height: 100%;
  display: flex;
  flex-direction: column;

  .partner-page-head-back {
    padding: 16px 16px 0 0;
  }

  .ant-tabs-content-top {
    height: 100%;
  }

  .ant-tabs-content-holder {
    flex: 1;
  }

  .preview-back-btn {
    &-arrow {
      padding: 2px 8px;
      border-radius: 100%;
      font-size: 18px;
      color: $theme-blue;
      box-shadow: 1px 1px 10px -1px rgb(51 102 204 / 75%);
    }

    &-text {
      margin-left: 16px;
    }
  }

  .partner-page-head-tabs-group {
    height: 100%;
    padding-top: 16px;

    .partner-page-preview-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 26px;
      height: 100%;
      background-color: $white-solid;

      iframe {
        width: 100%;
        flex-grow: 1;
        border: 1px solid $snowbank;
        box-shadow: 0 1px 4px $theme-black;
        border-radius: 8px;
      }

      .portal-content-footnote {
        padding: 16px 0 0;
        width: 100%;
      }
    }

    .mobile-page-preview {
      .partner-page-preview-wrapper iframe {
        width: 375px;
        height: 550px;
      }
    }

    .portal-clipboard {
      margin-right: 16px;
    }

    .preview-tab {
      &-icon {
        margin-right: 8px;
      }
    }

    .ant-tabs-nav {
      margin: 0;
    }
  }
}
