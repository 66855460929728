.implementaion-fee-waived-tag {
  background: $clear-skies !important;
  border-radius: $border-radius-base !important;
  border-color: $long-island-sound !important;
  color: $clear-chill !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  margin-right: 0 !important;
  padding: 2px 8px !important;
}
