.partner-page-pixels {
  .radio-button-flex-container {
    display: flex;
    align-items: center;
  }

  .partner-page-pixels-row {
    margin-bottom: 10px !important;

    .code-edit-modal-container {
      display: flex;

      .code-edit-button {
        margin-left: auto;
      }
    }

    &:first-child {
      margin-top: 15px;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: $font-weight-bold;
    }

    &-url-form-item {
      margin-top: 20px;
    }

    &-code-label {
      display: flex;
      justify-content: space-between;
    }

    &-code {
      font-family: monospace;
    }

    &-code-instructions-collapse {
      margin: 30px 0 20px;
    }

    &-code-instructions-panel {
      background: $lynx-white;
      border-radius: 4px !important;
      border: 0;
      overflow: hidden;
      padding: 5px 0 7px;
    }
  }
}
