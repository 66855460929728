.create-integration {
  &-heading {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: $font-weight-bold;

    .anticon {
      margin: 0 16px;
      color: $theme-blue;
      font-size: 12px;
    }
  }
}
