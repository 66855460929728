.portal-upload-drop-area {
  margin-bottom: 10px;

  .ant-upload-btn {
    padding: 24px 0 !important;
  }

  &-text {
    font-size: 14px;
    margin-top: 8px !important;
  }
}
