.self-service-guide {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $theme-gray-11;

  &-carousel {
    display: flex;
    flex-grow: 1;
    justify-content: center;

    &-wrapper {
      width: 60%;
    }

    &-button-wrapper {
      display: flex;
      align-items: center;
      min-width: 200px;
    }

    &-button-wrapper.left {
      justify-content: flex-end;
    }

    &-button-wrapper.right {
      justify-content: flex-start;
    }

    &-next-button {
      margin-top: 2px;
    }

    &-button {
      width: 48px !important;
      height: 48px !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;

      .arrow-right {
        transform: rotate(180deg);
      }

      svg {
        font-size: 32px;
        color: $celtic-blue;
        stroke: $celtic-blue;
        margin-top: 2px;
      }
    }
  }

  .logo-wrapper {
    text-align: center;
    padding: 38px 0 24px;

    .logo {
      svg {
        font-size: 80px;
      }
    }
  }

  .ant-carousel {
    .slick-dots-bottom {
      bottom: -50px;
    }

    .slick-dots li {
      height: 9px;
      width: 9px;
      background-color: $theme-gray-7;
      border-radius: 50%;
      transition: all 0.9s;
      margin: 0 8px;
    }

    .slick-dots li.slick-active {
      background-color: $theme-blue;
      height: 11px;
      width: 11px;
    }
  }
}
