@import "@portal/ui-lib/src/lib/_widgets/SiteHeader/style";

.portal-layout {
  height: 100%;

  .sticky-header {
    background: $theme-white;
    padding-left: 0;
    position: sticky;
    top: 0;
    z-index: 10;

    &-menu {
      line-height: 64px;

      &-item {
        &-right {
          float: right;
        }

        &-no-border {
          border-bottom: none !important;

          &.ant-menu-item-selected {
            color: rgb(0 0 0 / 65%) !important;
            border-bottom: none !important;
          }

          &.ant-menu-item-active {
            color: $theme-blue !important;
          }
        }

        &-notifications {
          font-size: 24px;

          .anticon {
            margin: 0;
            font-size: 24px;
          }
        }
      }
    }

    &-hidden {
      display: none;
    }

    .sidebar-toggle-trigger {
      border-bottom: none !important;
      left: 10px;
    }
  }
}
