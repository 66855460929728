@import "../../../styles/variables";

.bp4-navbar.blueprint-nav {
  box-shadow: none;
  padding: 0 10px;

  .trial-bar {
    margin-left: 10px;
    margin-right: 10px;
  }

  button {
    color: rgb(0 0 0 / 65%);
  }
}

:root {
  --fa-counter-background-color: #{$theme-red};
  --fa-counter-scale: 0.5;
  --fa-top: -10px;
  --fa-right: -10px;
  --fa-animation-iteration-count: 2;
}
