@mixin ant-success-alert-override {
  .ant-alert-success {
    background: $cold-white;
    border: 1px solid $mentos;
    border-radius: $border-radius-base;
    padding: 10px 10px 10px 36px;
    margin-bottom: $padding-lg;

    .ant-alert-icon {
      top: 13px;
      left: 13px;
      font-size: 16px;
    }

    .ant-alert-message {
      font-size: 14px;
      font-weight: $font-weight-bold;
    }

    .ant-alert-description {
      color: $theme-gray-9;
      font-size: 12px;
    }

    .ant-alert-close-icon {
      top: 50%;
      right: 10px;
      transform: translate(0%, -50%);
    }
  }
}
