.notifications-list {
  height: calc(100% - 46px);
  overflow: overlay;
  padding-top: $padding-lg;
  padding-left: 35px;
  padding-right: 35px;
}

.notifications-not-found {
  padding-top: 100px;
  text-align: center;
}
