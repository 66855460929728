@import "../../styles/mixins/text-overflow";

.icon-property {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 10px;

  &-icon {
    flex-shrink: 0;
  }

  &-text {
    @include text-overflow;

    margin-left: 14px;
  }
}
