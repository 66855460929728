@mixin table-with-id-name {
  td:nth-child(1),
  th:nth-child(1) {
    width: 90px;
  }

  td:nth-child(2),
  th:nth-child(2) {
    max-width: calc(100vw * 0.25);
    word-wrap: break-word;
  }
}
