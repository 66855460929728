.plain-details {
  .ant {
    &-page-header {
      margin: -18px -20px 20px;
      height: 48px;
      padding: 0 30px;
    }

    &-page-header-heading {
      height: 100%;
    }

    &-page-header-heading-title {
      font-size: 14px;
      font-weight: $font-weight-normal;
      color: $theme-blue;
    }

    &-page-header-back {
      font-size: 12px;
      margin-right: 10px;

      &-button {
        span {
          svg {
            fill: $theme-blue;
          }
        }
      }
    }
  }
}
