.purchase-plan {
  .plan-summary {
    background: $cascading-white;
    padding: 32px 26px 20px 22px;
    border-radius: 4px;
    max-width: 340px;
    height: min-content;
  }

  .portal-form-field-label {
    color: $theme-black;
  }
}
