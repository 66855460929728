.portal-switch {
  display: flex;

  .portal-form-field-label {
    flex-grow: 1;
  }

  .portal-form-field-element {
    margin-left: 8px;
    width: 51px;
  }
}
