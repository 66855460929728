.input-deductable-amount {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-input-number {
    flex-grow: 1;
    margin-right: 10px;
  }
}
