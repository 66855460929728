.tracking-tab {
  p {
    a {
      line-break: auto;
    }
  }

  .ant-card-extra {
    padding: 0;
    padding-top: 16px;
    margin-bottom: auto;
  }

  .ant-card-body {
    max-width: 85%;

    p {
      margin-bottom: 24px;
    }
  }

  &-card-title {
    position: relative;
    color: $theme-black;
  }

  &-lock {
    color: $theme-blue;
    position: absolute;

    svg {
      width: 12px;
      height: auto;
    }
  }

  &-description {
    font-size: 12px;
    color: $theme-gray-9;
    font-weight: $font-weight-normal;
    white-space: normal;
    margin-top: 8px;
    padding-right: 40px;
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.51;
  }

  &-native-example {
    margin: 0 !important;
  }

  &-pre-code {
    font-family: $font-family;
    font-size: 14px;
    margin-top: -20px;
  }
}
