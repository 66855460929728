.notifications-drawer {
  .ant-drawer-header,
  .ant-drawer-content {
    background-color: $theme-gray-4;
  }

  .ant-drawer-close {
    font-size: 20px;
    padding: 18px;
  }

  .ant-drawer-header {
    border: none;
    padding-left: 35px;
    padding-right: 35px;

    .ant-drawer-title {
      display: flex;
      align-items: center;

      .icon {
        font-size: 24px;
        margin-right: 12px;
      }

      .label {
        font-size: 13px;
      }
    }
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-tabs-top,
  .ant-tabs-bottom {
    .ant-tabs-nav {
      margin-left: 35px;
      margin-right: 35px;
      margin-bottom: 0;
    }

    &,
    & > div {
      > .ant-tabs-nav::before {
        border-bottom: 2px solid $theme-gray-6;
      }
    }
  }

  .ant-tabs-nav-list {
    width: 100%;
    display: flex;

    .ant-tabs-tab {
      color: $theme-gray-6;
      flex-basis: auto;
      flex-grow: 1;
      max-width: 100%;
      margin: 0;

      &:hover,
      &:focus {
        color: $theme-gray-9;
      }
    }

    .ant-tabs-tab-btn {
      width: 100%;
      text-align: center;

      &:focus {
        outline: none !important;
      }
    }
  }
}
