.agreement-modal-title {
  text-align: center;
  margin-bottom: 48px;
  margin-top: 40px;
}

.agreement-modal-content {
  max-height: 390px;
  overflow-y: scroll;
  padding: 0 180px;
}
