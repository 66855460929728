.payables-adjustments-table {
  .ant-table-tbody {
    .ant-table-cell {
      padding: 16px;

      .user-avatar {
        flex-direction: row;
      }

      @media (min-width: 1500px) {
        .note-cell {
          width: 200px;
        }
      }
    }
  }
}
