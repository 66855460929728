.portal-form-radio-group {
  display: flex;
  flex-flow: row wrap;

  &.vertical {
    flex-flow: column;

    .ant-radio-wrapper {
      margin-bottom: 12px;
    }
  }
}
