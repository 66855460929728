.ant-layout-sider-zero-width-trigger {
  top: 10px;
}

$customizer-padding: 20px;
$customizer-title-height: 60px;
$customizer-actions-height: 60px;

.partner-key-edit {
  display: flex;
  background-color: $theme-white;
  border-top: 1px solid $cold-morning;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: inherit;

  &-preview {
    &-back {
      padding: 22px !important;
      margin-bottom: 16px;
    }

    margin-bottom: 0;
    flex-grow: 10;
    height: 100%;

    &-tabs {
      height: 100%;

      .copy-pp-link {
        padding: 0 15px;
      }

      .copy-embed-button {
        margin-right: 10px;
        line-height: normal;
      }
    }

    .ant-tabs-tab {
      margin: 0 16px;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-nav-wrap {
      display: flex;
      justify-content: left;
    }

    .ant-tabs-content-holder {
      flex: 1;
    }
  }

  &-embed-preview {
    overflow-y: scroll;
  }

  &-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  &-customize {
    &-title {
      display: flex;
      font-size: 16px;
      font-weight: $font-weight-bold;
      line-height: 22px;
      text-align: left;
      height: $customizer-title-height;
      padding: 0 $customizer-padding;
      align-items: center;
      border-left: 1px solid $cold-morning;
    }

    &-form {
      height: calc(100% - #{$customizer-title-height});
      width: 450px;
      padding: 0;
      border-left: 1px solid $cold-morning;
      flex-grow: 1;

      form {
        height: calc(100% - #{$customizer-title-height});
      }

      .legacy-portal-form-item-horizontal-divider {
        margin-top: 36px;
      }

      .legacy-portal-form-item-horizontal-divider-small {
        margin-top: 12px;
      }

      .legacy-portal-form-tab-content {
        padding: 0 $customizer-padding;
      }

      .legacy-portal-form-actions {
        padding: 0 $customizer-padding;
        border-top: 1px solid $cold-morning;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: $customizer-actions-height;
        background-color: $theme-white;

        button:not(:last-of-type) {
          margin-right: 10px;
        }
      }

      .ant-tabs-nav {
        position: sticky;
        top: 0;
        z-index: 99;
        background-color: $theme-white;
        border-bottom: 1px solid $border-color-base;
      }

      .legacy-portal-form-tabs {
        height: 100%;
        overflow-y: scroll;

        &-validation {
          margin-right: 10px;
        }
      }

      .ant-form {
        height: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .offer-overflow {
          width: 284px;
          display: inline-block;
          margin-right: 10px;
        }

        .offer-overflow-savings {
          width: 284px;
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 0;
        }

        .page-size {
          display: inline-block;
        }

        .page-size-savings {
          display: inline-block;
          margin-bottom: 0;
        }
      }
    }
  }
}
