.user-avatar {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  padding: 10px 0;

  .ant-avatar-string {
    transform: scale(1) translateX(-50%) !important;
  }

  .ant-avatar-sm {
    .ant-avatar-string {
      font-size: 12px;
    }
  }

  &-item {
    .ant-menu-submenu-title {
      padding: 0 5px;
    }
  }

  &-name {
    margin-left: 10px;
  }

  &-component {
    flex-shrink: 0;
  }

  .user-avatar-name {
    display: flex;
    flex-direction: column;

    .username {
      font-size: 13px;
      line-height: 20px;
    }

    .ant-tag {
      font-size: 7px;
      border-radius: 20px;
      border: none;
    }
  }
}
