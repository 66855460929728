.integration-options {
  max-width: 888px;
  width: 100%;
  margin: 32px auto;

  @media (max-width: 1180px) {
    padding-left: $padding-lg;
    padding-right: $padding-lg;
  }

  &-header {
    text-align: center;
    background: $theme-blue;
    border-radius: 6px 6px 0 0;
    color: $theme-white;
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: normal;
    padding: 4px;
  }

  &-body {
    background: $theme-white;
    border-radius: 0 0 6px 6px;
    border: 1px solid $cold-morning;
    border-top: none;

    @media (min-width: 920px) {
      display: grid;
      justify-content: space-around;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: $padding-lg;
    }
  }

  .integration-option {
    text-align: center;
    padding: 20px;

    .waived {
      text-decoration: line-through;
    }

    p {
      color: $theme-gray-9;
      font-size: 20px;
      font-weight: $font-weight-bold;
      line-height: normal;
      margin-bottom: 5px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 8px;

        .a {
          fill: $hydrargyrum;
        }
      }

      span {
        color: $hydrargyrum;
      }
    }
  }
}
