.subaccount-create {
  &-field-title {
    font-size: 16px;
    color: $theme-gray-7;
    font-weight: $font-weight-semibold;
    margin-top: 40px;
  }

  &-bc-error {
    color: $theme-red;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: $font-weight-bold;
  }

  &-bc-alert {
    margin-bottom: 15px;
  }

  &-dp {
    width: 100%;
  }
}

.supply-sub-account-create {
  form {
    .horizontal-switch {
      display: flex;

      > :first-child {
        display: flex;
        flex-grow: 1;
      }
    }

    .horizontal-input-number {
      display: flex;
      justify-content: space-between;

      :first-child {
        flex-grow: 1;
        text-align: left;
      }

      .ant-form-extra {
        text-align: right;
      }
    }
  }
}
