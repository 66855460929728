.portal-selectable-card {
  border-radius: 10px !important;
  border: 2px solid $border-color-base !important;
  cursor: pointer;
  text-align: center;
  width: 20vw;
  height: 20vw;
  min-height: 145px;
  min-width: 160px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  display: grid;
  align-items: center;
  margin: 8px;

  h4 {
    font-size: 16px;
  }

  &-small {
    width: calc(20% - 16px);
    max-width: 217px;
    height: 9vw;
  }

  &-large {
    width: 25vw;
    height: 23vw;
  }

  img {
    width: 90%;
  }

  &:hover {
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
  }

  .card-icon svg {
    fill: $theme-blue;
    font-size: 30px;
    margin: 5px 0;
  }

  &-locked {
    opacity: 0.5;

    .card-icon svg {
      fill: $theme-gray-7;
    }
  }

  &-selected {
    border: 3px solid $theme-blue !important;
    box-shadow: 0 14px 14px rgb(35 124 241 / 20%);
  }

  &-extra {
    position: absolute;
    right: 18px;
    top: 18px;
  }
}

.card-select-input {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 15px 4%;
}
