.total {
  display: flex;
  justify-content: space-between;
  padding: 40px 15px;
  font-size: large;

  &-label {
    font-weight: bold;
  }
}
