.compliance-row-card {
  margin-bottom: 0 !important;

  &-section {
    border-left: 5px solid lightgray;
    margin: 0 10px;
    padding: 0 10px;
  }

  p {
    margin-top: 10px;
  }
}
