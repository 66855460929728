.performance-summary-stat-bar {
  .ant-card-body {
    padding: 0 !important;
    margin-top: $margin-lg;
  }

  .channel-name {
    font-size: 12px;
    line-height: 18px !important;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  .product-badge {
    font-size: 14px;
    margin-right: 4px;
  }

  .stat-bar {
    box-shadow: 0 1px 2px $black-75pcnt;

    &-item {
      margin-bottom: 0;
      padding: 0 $padding-lg 0 $padding-lg;

      &-label {
        max-width: 100%;
        display: inline;
        border: 0;
      }
    }
  }
}
