@import "../../../styles/mixins/ant-success-alert-override";

.self-service-integrations-publish-success-note {
  @include ant-success-alert-override;
}

.self-service-integrations-table {
  padding: 0;

  .ant-table-wrapper.even-table {
    padding-bottom: 25px;
  }

  .portal-clipboard {
    white-space: nowrap;
  }

  .icon {
    margin-right: 16px;
    font-size: 17px;
    vertical-align: middle;
  }

  .even-table {
    &-action-bar {
      margin-bottom: 0;

      &-title {
        color: $theme-gray-11;
      }
    }

    .ant-spin-nested-loading {
      min-height: 100%;
      height: auto;
    }
  }

  .ant-table {
    background: transparent;
    margin-top: $margin-lg;

    table {
      border-spacing: 0 1px !important;
      width: calc(
        100% - 4px
      ) !important; // needed to display box shadow properly

      margin-left: 2px;
      margin-right: 2px;

      th {
        color: $theme-gray-9 !important;
        font-size: 12px !important;
        font-weight: $font-weight-bold !important;
        white-space: nowrap;
      }

      thead {
        tr,
        th {
          background-color: transparent !important;
        }
      }

      tbody {
        tr.ant-table-expanded-row {
          background-color: transparent !important;
          box-shadow: none;

          td {
            background: transparent !important;
            padding: 0 0 $padding-lg 0;

            .integration-expanded-row {
              padding: $margin-lg;
              background: $theme-gray-4;
              box-shadow: 0 1px 2px $black-75pcnt;
              border-radius: 0 0 3px 3px;
            }
          }
        }

        tr:not(.ant-table-expanded-row) {
          td {
            padding: $margin-lg;

            &:last-of-type {
              padding-right: 0;
            }
          }

          .first-column {
            border-radius: 3px 0 0 3px;
            border-left: 1px solid $cold-morning;
          }
        }
      }
    }

    &-column-sorters {
      padding: 10px 8px;

      &:first-of-type {
        padding-left: 14px;
      }
    }

    &-row-expand-icon-cell {
      display: none !important;
    }
  }
}
