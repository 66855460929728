@import "../../../styles/mixins/tabs-navbar-center";

.legacy-portal-form {
  .ant-col {
    width: 100%;
  }

  &-item {
    > * {
      line-height: 20px;
    }

    > div:first-child {
      margin: 0 0 5px;
      text-align: left;
    }

    label {
      display: inline-block;
    }
  }

  &-item-label {
    font-weight: $font-weight-bold;
  }

  &-item-tooltip {
    margin-left: 5px;
    vertical-align: -0.125em !important; // overwrite .ant-legacy-form-item label > .anticon
  }

  &-title {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: $font-weight-bold;
  }

  &-tabs {
    @include tabs-navbar-center;
  }

  @media (max-width: 1100px) {
    &-item {
      > div {
        width: 100%;
      }
    }
  }
}
