.regenerate-menu-button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.regenerate-menu-button:disabled {
  cursor: not-allowed;
}
