.full-page-carousel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-body-container {
    margin-bottom: 120px;
    position: relative;
  }

  &-body-content {
    position: absolute;
    font-size: 16px;
    color: $border-color-base;
    width: 100%;
    text-align: center;
  }

  &-title {
    color: $theme-white !important;
    font-size: 40px !important;
    font-weight: $font-weight-normal !important;
    text-align: center;
  }

  &-image {
    img {
      box-shadow: 0 0 16px $theme-gray-9;
      border-radius: 6px;
    }
  }
}
