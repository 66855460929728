.self-service-account-upgrade-scope {
  .self-service-account-upgrade-header {
    background: $theme-white;
    border-top: 1px solid $border-color-base;
    padding: $margin-lg;
    padding-bottom: 0;
  }

  .ant-tabs-nav-wrap {
    background: $theme-white;
  }

  .ant-tabs-nav-wrap {
    padding-left: 20px;
  }

  h1 {
    font-size: 22px;
    font-weight: $font-weight-bold;
    margin: 0;
    padding-top: 16px;
    padding-bottom: 12px;
  }

  .portal-form {
    max-width: 60%;
    margin: auto;

    @media (max-width: 1400px) {
      max-width: 80%;
    }

    @media (max-width: 950px) {
      max-width: 100%;
    }
  }

  .portal-form-field-extra {
    flex-basis: 100%;
    position: relative;
    left: 28%;
  }

  .portal-form-field {
    flex-wrap: wrap;

    .portal-form-field-label-inline {
      flex: 1;
    }

    .portal-form-field-element {
      flex: 3;
    }

    .portal-form-field-error {
      transform: translateX(28%);
    }
  }

  .portal-form-field-label-inline {
    text-align: right;
    margin-right: 10px;
  }

  .custom-fields {
    .portal-form-field-element {
      display: block;
    }
  }

  .portal-form-field-error {
    width: 100%;
  }

  .portal-form-actions {
    float: right;

    button {
      margin-right: 10px;
    }
  }
}
