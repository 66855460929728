.integration-details-sidebar-item {
  margin-top: 0;
}

.integration-details-sidebar {
  &-partner-page {
    &-input {
      margin-bottom: 8px;
    }

    &-preview-button {
      display: flex;
    }
  }
}
