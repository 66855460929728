.paid-subscription-state {
  .title-wrapper {
    p {
      margin: 0;
    }

    .sub-title {
      color: $theme-red;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .actions {
    #activate-subscription {
      margin-right: 16px;
    }
  }
}
