.save-url {
  position: relative;
  top: 15px;

  &-action {
    display: flex;

    .save-url-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .save-url-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }

  &-error {
    color: $theme-red;
    font-size: 12.9px;
    margin: 8px 0 0;
    opacity: 0;
    transition: opacity 0.5s;

    &.is-visible {
      opacity: 1;
    }
  }
}
