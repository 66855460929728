.upgrade {
  background-color: $theme-white;
  border-radius: $border-radius-base;
  box-shadow: 0 0 20px -10px $theme-blue;
  padding: $padding-lg;
  margin-bottom: 15px;

  .upgrade-content {
    width: 90%;

    p {
      margin: 0 0 20px;
    }

    hr {
      margin: 20px 0;
      width: 100%;
      border: 1px solid $theme-gray-4;
      border-top: 0;
    }

    ul {
      margin: 0;
      padding: 0 0 0 20px;
    }

    li {
      margin: 0 0 10px;
    }
  }

  .upgrade-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 20px;
  }

  .upgrade-button {
    background-color: $theme-blue;
    color: $theme-white;
  }
}
