@use "sass:color";
@import "./blueprint/button";
@import "./blueprint/checkbox";
@import "./blueprint/dialog";
@import "./blueprint/disabled";
@import "./blueprint/headings";
@import "./blueprint/popover";
@import "./blueprint/radio";
@import "./blueprint/non-ideal-state";
@import "./blueprint/tabs";

a {
  color: $link-color;

  &:hover {
    color: $link-color-on-hover;
    text-decoration: none;
  }

  &:active {
    color: $link-color-on-active;
  }
}

.bp4-tag:not(.trial-bar) {
  padding: 4px !important;
  border: 1px solid $lilac-mist !important;
  background: $white-smoke !important;
  border-radius: 2px !important;
  color: $ships-officer !important;
}

.bp4-card {
  padding: 0 !important;
  font-family: "Open Sans", sans-serif !important;

  .ag-root-wrapper {
    border-top: 1px solid $lilac-mist !important;
    border-right: none !important;
    border-left: none !important;
  }

  h5 {
    padding: 20px 24px !important;
    margin: 0 !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }
}

.bp4-non-ideal-state .bp4-heading {
  font-size: 24px;
  font-weight: 400;
  white-space: nowrap;
}

.bp4-numeric-input {
  .bp4-input-group {
    width: calc(100% - 30px);
  }
}
