.bp4-radio {
  .bp4-control-indicator {
    background: $theme-white !important;
  }

  input ~ .bp4-control-indicator {
    box-shadow: inset 0 0 0 1px $radio-active-color,
      inset 0 -1px 0 $radio-active-color !important;
  }

  input:focus ~ .bp4-control-indicator {
    outline: none !important;
  }

  input:checked ~ .bp4-control-indicator {
    background-color: $theme-white !important;

    &::before {
      background-image: radial-gradient(
        $radio-active-color,
        $radio-active-color 40%,
        $theme-black 40%
      ) !important;
    }
  }

  input:hover:not(:checked) ~ .bp4-control-indicator {
    box-shadow: inset 0 0 0 1px $blue-jeans, inset 0 -1px 0 $blue-jeans !important;
  }
}
