.demand-contract-details {
  &-heading {
    font-weight: $font-weight-bold;
    font-size: 20px;
    padding-top: 20px;
  }

  &-content {
    display: flex;

    &-left > div:first-child,
    &-right > div:first-child {
      font-size: 14px;
      font-weight: 700;
      color: $ships-officer;
    }

    &-left {
      width: 35%;
      max-width: 300px;
    }
  }
}
