.repeatable {
  &-error {
    margin: 0 0 $margin-lg !important;
  }

  &-add-one {
    width: 100%;

    &.is-hidden {
      display: none;
    }

    &.is-disabled {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}

.repeatable-item {
  opacity: 0;
  transition: 0.5s opacity ease;

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-title {
    color: $spanish-grey;
    margin: 0 0 8px;
  }

  &-remove {
    $size: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-color-base;
    border-radius: $size;
    width: $size;
    height: $size;
    cursor: pointer;
  }

  &.is-visible {
    opacity: 1;
  }
}
