.performance-summary-scope {
  border-top: 1px solid $border-color-base;

  .alert-box {
    margin-bottom: $margin-lg;
  }

  .performance-summary-header {
    padding: $margin-lg;
    background: $theme-white;

    .even-table-action-bar-buttons > * {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &.blank-page {
    .performance-summary-header {
      padding-bottom: $margin-lg;
    }
  }

  .revenue-reports-filter {
    margin-bottom: 0 !important;
  }

  .performance-summary-no-data {
    padding: $padding-lg;
  }

  .revenue-reports-subaccounts {
    margin-right: 8px !important;
  }

  .revenue-reports-groupby-dropdown {
    min-width: 132px;
  }

  .performance-summary-spin {
    width: 100%;
    padding: $padding-lg;
  }

  .performance-summary-groupby {
    &-label {
      margin-right: 8px;
    }

    &-input {
      min-width: 152px;
      max-width: 100px;
      margin-left: 8px;
    }
  }

  .performance-summary-tabs {
    .ant-tabs-nav,
    .ant-tabs-content-holder {
      padding: 0 $margin-lg;
      margin-bottom: $margin-lg !important;
    }

    .ant-tabs-tab {
      padding: 12px 0 !important;
    }

    .ant-tabs-nav {
      background: $theme-white;
    }

    .ant-tabs-tabpane-active:focus {
      outline: none !important;
    }
  }

  .performance-summary-table {
    height: auto !important;

    .ant-table {
      border: 1px solid $border-color-base;

      [style="text-align: right;"] {
        .ant-table-filter-column {
          justify-content: flex-end;
        }
      }
    }

    &-hide-search {
      .ant-table-filter-trigger-container {
        display: none !important;
      }
    }
  }
}

.new-feature {
  margin-bottom: 20px;
}
