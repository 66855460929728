.stat-bar-container {
  .stat-bar {
    border-radius: 3px;
    margin: 0 0 24px !important;
    padding: 24px 0;
    border: unset !important;
    outline: 1px solid $lilac-mist !important;

    > div {
      display: flex;
    }

    &-item {
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      border-right: 1px solid $mercury;
      border-bottom: 0;
      box-shadow: none;
      padding: 0 20px;
      position: relative;

      &.has-tooltip {
        padding: 0 16px;
      }

      &:last-of-type {
        border: 0;
      }

      &-label {
        font-size: 12px;
        line-height: 18px;
        color: $ships-officer;

        &-with-tooltip {
          flex: none;
        }
      }

      &-label,
      &-value {
        flex: 0 0 100%;
      }

      &-value {
        font-size: 16px;
        line-height: 24px;
        font-weight: $font-weight-bold;
        color: $ships-officer;
        margin: 8px 0 0;
      }

      &-tooltip:not(.stat-bar-item-label) {
        color: $spanish-grey;
        font-size: 12px;
        position: absolute;
        top: 5px;
        right: 15px;
      }
    }

    @media (max-width: 1200px) and (min-width: 992px) {
      > div {
        flex-wrap: wrap;
      }

      &-item {
        flex: 0 0 33.33%;

        &:nth-child(3n) {
          border-right: 0;
        }
      }
    }

    @media (max-width: 991px) and (min-width: 501px) {
      > div {
        flex-wrap: wrap;
      }

      &-item {
        flex: 0 0 50%;

        &:nth-child(2n) {
          border-right: 0;
        }
      }
    }

    @media (max-width: 500px) {
      > div {
        flex-wrap: wrap;
      }

      &-item {
        border: 0;
        flex: 0 0 100%;
      }
    }
  }
}
