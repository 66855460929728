@import "../../../../styles/mixins/table-as-card";

.demand-partners {
  @include table-as-card;

  .demand-api-badge {
    margin-bottom: 10px;
  }

  .column-badge {
    margin-right: 10px;
  }

  .is-second-look-tag {
    margin-left: 0;
  }

  .second-look-column {
    display: flex;
    align-items: center;
  }
}

.demand-inclusion-card {
  & > .body {
    padding: 24px;
  }
}
