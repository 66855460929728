.dsa-settings {
  $card-border-radius: 6px;
  $card-margin-bottom: 20px;

  .dsa-settings-card {
    margin-bottom: $card-margin-bottom;

    &-head-title {
      font-weight: $font-weight-semibold;
    }

    &-logo-image {
      max-width: 300px;
      height: auto;
      padding: 10px;
      border: 1px solid $border-color-base;
      border-radius: 4px;
    }
  }

  .dsa-settings-card > div {
    border-radius: $card-border-radius;
  }

  .credit-card-settings-card {
    border-radius: $card-border-radius;
    margin-bottom: $card-margin-bottom;
  }

  .us-states-card-form {
    margin-bottom: $card-margin-bottom;
  }

  .us-states-card-form > div {
    border-radius: $card-border-radius;
  }
}
