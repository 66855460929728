.key-value-tags {
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    &-key {
      width: 20%;
    }

    &-value {
      width: 70%;
    }
  }

  &-row:first-child {
    margin-bottom: 0 !important;
  }

  &-button {
    margin-top: 20px;
    width: 100%;
  }
}
