.chart-tooltip {
  background-color: rgba($theme-white, 0.85);
  border-radius: 2px;
  box-shadow: 0 0 7px 4px rgb(0 0 0 / 10%);
  color: inherit;
  font-size: inherit;
  min-width: 150px;
  padding: 5px 10px;

  &-label {
    display: block;
    margin: 0 0 5px;
  }

  &-value {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex: 0 0 auto;
    margin: 0;
  }

  &-value-legend {
    margin: 0 10px 0 0;

    &::before {
      $size: 8px;

      content: "";
      background: $theme-blue;
      border-radius: $size;
      display: inline-block;
      width: $size;
      height: $size;
      margin: 0 10px 0 0;
    }
  }
}
