.integration-fee {
  .integration-fee-title.ant-typography {
    font-size: 32px;
    font-weight: $font-weight-normal;
    margin: 0;
    padding-bottom: 8px;
    text-align: center;
  }

  &-subtext {
    width: 530px;
    max-width: 90%;
    text-align: center;
    margin: auto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
  }

  &.ant-layout {
    background-color: $theme-white;
  }

  .ant-layout-content {
    margin: 45px auto 0;
  }

  .ant-select-dropdown {
    width: unset !important;
  }

  &-form-component {
    margin-top: 40px;
  }
}
