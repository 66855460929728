.account-owners-card {
  form {
    .ant-col-6 {
      max-width: 32%;
      flex: 0 0 32%;
    }

    .ant-col-18 {
      max-width: 68%;
      flex: 0 0 68%;
    }
  }
}
