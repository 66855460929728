.plan-integration-card {
  border-bottom: 1px solid $lilac-mist;
  padding: 7px 0;

  > * {
    padding: 7px 0;
  }

  .waived {
    text-decoration: line-through;
  }
}
