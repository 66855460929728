.line-and-bar-chart {
  position: relative;

  &-toggler {
    border: 1px solid $saltwater;
    border-radius: 5px;
    display: flex;
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 10;

    &-toggle {
      background-color: $theme-white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 35px;
      width: 45px;
      font-size: 16px;

      &.is-active {
        background-color: $theme-blue;
        color: $theme-white;
      }
    }
  }
}
