$column-right-padding: 24px;

.first-integration-page {
  text-align: center;
  flex-grow: 1;

  &-text {
    padding: 35px 0;
  }
}

.card-wrapper-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;

  .card-wrapper {
    .tutorial-line-wrapper {
      text-align: left;
      margin-left: 10px;
    }
  }
}

.first-integration-form {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  font-size: 16px;

  &-text {
    padding: 30px 0;
  }

  &-field {
    margin: 18px 0 !important;
    padding: 0 4% !important;
    text-align: left;
  }

  &-card-select-field {
    text-align: center;

    .portal-form-field-label {
      text-align: left;
      margin-top: 12px;
      margin-left: 48px;
    }

    .portal-form-field-element {
      display: block;
    }
  }

  .portal-form-actions {
    display: grid;
    justify-content: center;

    button {
      margin: 10px 0;
      height: 44px;
      padding: 6.4px 15px;
      font-size: 16px;
    }
  }
}

.integration-url-field {
  width: 100%;

  .ant-input-prefix {
    margin-right: 0;
  }
}

.integration-name-field-text {
  float: right;
  font-size: 14px;
  font-style: italic;
}

.integration-preview-modal {
  .modal-body {
    display: flex;

    .column-left {
      width: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .column-right {
      position: relative;
      width: 50%;
      padding: $column-right-padding;

      .paragraph-title {
        display: inline-block;
        margin-bottom: 16px;
        font-weight: $font-weight-bold;
        color: $theme-black;
      }

      .pricing {
        position: absolute;
        right: $column-right-padding;
        bottom: $column-right-padding;
        text-align: right;

        span {
          display: block;

          &:first-of-type {
            font-style: italic;
            font-size: 10px;
            color: $theme-gray-11;
          }

          &:last-of-type {
            color: $theme-blue-light;
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }

  &.video {
    .modal-body {
      .column-left {
        flex-grow: 1;
        padding: 24px;
        height: 344px;
      }

      .column-right {
        width: 300px;
      }
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-title {
    font-weight: $font-weight-bold;
  }
}
