@import "@portal/ui-lib/src/styles/mixins/table-with-id-name";

.revenue-reports-scope {
  .alert-box {
    margin-bottom: 20px;
  }

  .revenue-report {
    &-table {
      @include table-with-id-name;
    }
  }

  .revenue-reports {
    $input-spacing-x: 15px;
    $input-spacing-y: 10px;

    &-splash {
      text-align: center;
    }

    &-filter {
      display: flex;
      flex-basis: 0;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 0 (20px - $input-spacing-y);

      &-right,
      &-left {
        > * {
          margin-bottom: $input-spacing-y;
        }

        > :not(:last-child) {
          margin-right: $input-spacing-x;
        }
      }

      &-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &-revenue-type {
      &-radio-item {
        border: 1px solid $kingly-cloud;
        box-shadow: none;
        transition: none;

        &-tooltip {
          $size: 14px;

          width: $size;
          height: $size;
          margin: -3px 0 0 5px;
          background-color: $border-color-base;
          border-radius: $size;
          color: $theme-white;
          font-size: 10px;
          vertical-align: middle;

          svg {
            margin: 2px 0 0;
          }
        }

        &.is-checked {
          border-color: $theme-blue;
          background-color: $theme-blue;
          color: $theme-white !important;

          .revenue-reports-revenue-type-radio-item-tooltip {
            background-color: $dithered-sky;

            svg {
              color: $theme-blue;
            }
          }
        }

        &:hover {
          color: inherit;
        }
      }
    }

    &-groupby {
      display: flex;
      align-items: center;

      > :not(:last-child) {
        margin-right: $input-spacing-x;
      }

      &-dropdown {
        width: 140px;
      }
    }

    @media (max-width: 768px) {
      &-filter-right,
      &-filter-left {
        flex: 0 0 100%;
      }

      &-subaccounts,
      &-revenue-type,
      &-range-picker,
      &-groupby {
        width: 100%;
      }

      &-revenue-type > * {
        width: 50%;
      }
    }
  }

  .is-loading {
    opacity: 0.5;
  }
}
