.user-create {
  &-field-title {
    font-size: 16px;
    color: $theme-gray-7;
    font-weight: $font-weight-semibold;
    margin-top: 40px;
  }

  &-bc-error {
    color: $theme-red;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: $font-weight-bold;
  }

  &-bc-alert {
    margin-bottom: 15px;
  }

  &-dp {
    width: 100%;
  }
}

.user-confirmation-modal {
  .ant-modal-title {
    font-weight: $font-weight-bold;
  }
}
