.contract-clause {
  &-text {
    text-align: left;
    font: bold 12px/21px sans-serif;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0;
    color: $theme-gray-9;
    margin-bottom: 8px;
  }

  &-value {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0;
    color: $theme-gray-9;
    height: 32px;
    padding-top: 4px;
    display: flex;
    align-self: center;
    flex-direction: column;
  }
}

.contract-history-card > .ant-card-body {
  padding: 0;
}
