.legal-agreement {
  &-format {
    h1,
    h2,
    h3 {
      margin: 16px 0;
      font-weight: 600;
    }

    table {
      width: auto;
      border-collapse: collapse;
    }

    table,
    tr,
    th,
    td {
      border: 1px solid $steam;
    }
  }
}

.legal-agreement-format table > th,
.legal-agreement.format table > td {
  padding: 8px;
}
