.code-editor {
  border: 1px solid $border-color-base;
  border-radius: 4px;
  height: calc(70vh - 200px);
  overflow: scroll;
  padding: 10px;

  &-inner {
    font-family: "Fira code", "Fira Mono", monospace;
    font-size: 12;
    min-height: 100%;
  }

  &-textarea {
    outline: 0;
  }
}
