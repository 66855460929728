@import "../../../../styles/mixins/table-as-card";

.ssa-applications-tab {
  .partner-key-row {
    margin-bottom: 20px;

    &-badge {
      margin-left: 20px;
    }
  }

  .supply-partner-tokens {
    @include table-as-card;

    &-alert {
      margin: 20px;
    }
  }
}
