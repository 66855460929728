@use "sass:math";

@mixin form-field-grid {
  @at-root {
    @for $size from 1 through 11 {
      .column-#{$size} {
        $percent: math.div($size, 12) * 100;

        width: calc(#{$percent}% - #{$input-gutter * 0.5});

        &:not(:last-of-type):not(:first-of-type) {
          width: calc(#{$percent}% - #{$input-gutter});
        }
      }
    }
  }
}

.portal-form-row {
  display: flex;
}

.portal-form-field {
  @include form-field-grid;

  flex-grow: 1;
  line-height: 20px;
  margin: 0 0 20px;

  &.last-odd-element {
    flex-grow: 0;
  }

  &:first-of-type:not(:last-of-type) {
    margin-right: $input-gutter * 0.5 !important;
  }

  &:not(:last-of-type):not(:first-of-type) {
    margin-left: $input-gutter * 0.5 !important;
    margin-right: $input-gutter * 0.5 !important;
  }

  &:last-of-type:not(:first-of-type) {
    margin-left: $input-gutter * 0.5 !important;
  }

  &-inline {
    display: flex;
  }

  &-label {
    font-weight: $font-weight-bold;
    margin: 0 0 10px;
    text-align: left;

    label {
      display: inline;
    }

    &-inline {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  &-tooltip {
    svg {
      color: $theme-blue;
    }

    margin-left: 5px;

    &.small-tooltip {
      svg {
        color: $theme-gray-6;
        width: 12px;
        height: 12px;
      }
    }

    &.right:not(.checkbox) {
      font-size: 18px;
      margin-left: 10px;

      svg {
        margin-top: 7px;
      }
    }
  }

  &-element {
    position: relative;
    width: 100%;
    display: flex;

    .date-picker {
      width: 100%;
    }

    &-inline {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }

  &-recurly-element {
    padding: 0 8px !important;
    height: auto;

    iframe {
      vertical-align: middle;
      max-height: 32px;
    }
  }

  &-error {
    color: $theme-red;
    font-size: 12.9px;
    margin: 8px 0 0;
    opacity: 0;
    transition: opacity 0.5s;

    &.is-visible {
      opacity: 1;
    }
  }

  &-extra {
    font-size: 12.9px;
    margin: 8px 0 0;
    opacity: 0.6;
    transition: opacity 0.5s;
  }

  .field-label-wrapper {
    line-height: 32px;
    margin-right: 10px;
  }
}
