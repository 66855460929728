.card-title-scope {
  &.is-inside {
    .card-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: $font-weight-bold;
      font-size: 15px;
      flex-wrap: wrap;
    }
  }

  &.is-outside {
    .card-title {
      display: flex;
      align-items: center;
      color: $theme-gray-9;
      font-size: 20px;
      font-weight: $font-weight-bold;
      margin: 0 0 15px;

      &-view-more {
        color: $theme-blue;
        font-size: 14px;
        font-weight: normal;
        margin: 0 0 0 6px;
      }
    }
  }
}
