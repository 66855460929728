@import "../../../../styles/mixins/table-as-card";

.supply-partners-table {
  @include table-as-card;

  td:nth-child(1),
  th:nth-child(1) {
    max-width: calc(100vw * 0.25);
    word-wrap: break-word;
  }

  .ant-table-row td:nth-of-type(3) {
    border-right: 1px solid $border-color-base;
  }
}
