.self-service-account-upgrade-scope {
  .self-service-account-upgrade-header {
    background: $theme-white;
    border-top: 1px solid $border-color-base;
    padding: $margin-lg;
    padding-bottom: 0;
  }

  .ant-tabs-nav-wrap {
    background: $theme-white;
  }

  .ant-tabs-nav-wrap {
    padding-left: 20px;
  }

  h1 {
    font-size: 22px;
    font-weight: $font-weight-bold;
    margin: 0;
    padding-top: 16px;
    padding-bottom: 12px;
  }
}
