.free-trial-expired-banner {
  background: $sheer-peach 0% 0% no-repeat padding-box;
  border: 1px solid $cornmeal;
  border-radius: $border-radius-base;
  margin-bottom: $margin-lg;
  padding: 22px 72px;

  &-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: revert;
    text-align: center;
    background: $messinesi;
    border-radius: 50%;
    top: 16px !important;
    left: 16px !important;
    font-size: 20px !important;

    svg {
      margin: auto;
      fill: $theme-orange;
    }
  }

  .message {
    display: flex;
    align-items: center;

    &-title {
      color: $theme-gray-11;
      font-size: 20px;
      font-weight: $font-weight-bold;
    }

    &-note {
      color: $theme-orange;
      font-size: 10px;
      font-style: italic;
      margin-left: 16px;
    }
  }

  .ant-alert-description {
    color: $theme-gray-9;
    font-size: 12px;
  }
}
