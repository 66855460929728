@import "./ant-component-styling";

@mixin table-as-card {
  @include ant-component-styling;

  margin: 0 0 20px;
  padding: 0;

  .even-table-action-bar {
    padding: 12px 24px;
    border-bottom: 1px solid $unicorn-silver;
    color: rgb(0 0 0 / 85%);
    margin-bottom: 0;
  }

  .ant-pagination {
    padding-left: 15px;
  }
}
