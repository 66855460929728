.plan-summary-field-list {
  .portal-form-field-label {
    color: $theme-gray-9;
    font-size: 12px;
  }

  .billing-cycle-dropdown {
    width: 292px;
  }

  .billing-cycle-dropdown,
  .plan-duration-dropdown {
    .portal-form-field-label {
      &::before {
        content: "*";
        color: $theme-red;
      }
    }
  }

  .integration-field {
    max-width: 165px;
  }
}
