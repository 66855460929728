.contact-list-item {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &-title,
  &-name {
    text-align: left;
  }

  &-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    letter-spacing: 2px;
    margin-bottom: 10px;
    color: $theme-gray-8;
  }

  &-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
  }

  &-name {
    margin-bottom: 10px;
  }

  &-entry {
    margin-left: 3px;
  }
}
