.panel-card {
  &-title {
    margin-bottom: 0;
  }

  &-title-description {
    margin-top: 10px;
    font-size: 12px;
    font-weight: normal;
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &-hide-children {
    .ant-card-body {
      padding: 0;
    }
  }
}
