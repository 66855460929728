.layout-without-sidebar {
  height: 100%;
  background: $theme-white;

  .ant-layout-header {
    display: flex;
    background: $theme-white;
    border-bottom: 1px solid $theme-gray-4;
  }

  &-logo-wrapper {
    flex: 1;
  }

  &-logo {
    margin: auto;
    height: 100%;

    .a {
      fill: $theme-blue;
    }
  }
}

.ant-layout {
  background: $theme-white;
}
