.partner-page-form {
  .form-title {
    margin: 0;
    padding: 16px 32px;
    font-size: 22px;
    font-weight: $font-weight-bold;
    border-bottom: 1px solid $cold-morning;
  }

  .form-subtitle {
    margin: 0;
    padding: 16px 32px;
    font-weight: $font-weight-bold;
    border-bottom: 1px solid $cold-morning;
  }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;

    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 12px;
      }
    }
  }

  .form-fields {
    padding: 16px;

    .ant-input-number {
      width: 100%;
    }

    .ant-upload-list.ant-upload-list-picture {
      max-width: 50%;
    }

    .form-section-header {
      .form-section-title-wrapper {
        display: flex;
        margin-top: 20px;
        margin-bottom: 8px;
        padding-bottom: 6px;
        border-bottom: 1px solid $cold-morning;

        .form-section-title-lock-icon {
          font-size: 12px;
          color: $theme-gray-5;
          margin: 4px 8px 0 0;
        }

        .form-section-title {
          margin-bottom: 0;
          margin-top: 4px;
          font-size: 16px;
          font-weight: $font-weight-bold;
        }

        svg {
          width: 19px;
          height: 19px;
        }
      }

      .form-section-upgrade-plan-wrapper {
        display: flex;
        font-size: 16px;

        .form-section-upgrade-plan-message {
          margin-left: 4px;
        }
      }
    }
  }

  .portal-form-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 8px 0;

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }

  .hex-color-input-form {
    max-width: initial;
  }
}
